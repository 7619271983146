import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Spacer, PopUp, WPCButton, ModalActions,
} from '@/components';
import classes from './styles.module.scss';
import { AuthRepository } from '@/repositories';
import Assets from '@/core/api/Assets';

const User = Assets.getAssets('/general/user.svg');
type Props = {
  openPopUp: boolean,
  closePopUp: () => void,
};

const PopUpDefaultPayment: FC<Props> = ({ openPopUp, closePopUp }) => {
  const { t } = useTranslation();

  const [memberId, setMemberId] = useState<string | null>();

  useEffect(() => {
    (async () => {
      const sub = await AuthRepository.getMemberId();
      setMemberId(sub);
    })();
  }, []);

  return (
    <PopUp
      isOpen={openPopUp}
      onClose={() => closePopUp()}
      title={t('pop_up_default_payment.title')}
    >
      <div>
        <div className={classes.root}>
          <img src={User} alt="error" className={classes.img} />
          <div className={classes.container}>
            <Spacer vertical={1} />
            <div> { t('pop_up_default_payment.subtitle') } </div>
            <Spacer vertical={2} />
          </div>
        </div>
        <ModalActions>
          <WPCButton
            variant="primary"
            onAction={() => { window.location.href = `${REACT_APP_ZIQY}?page=ACCOUNT&member_id=${memberId}`; }}
          >
            {t('pop_up_default_payment.action')}
          </WPCButton>
        </ModalActions>
      </div>
    </PopUp>
  );
};

export default PopUpDefaultPayment;
