import LocalStorage from '@/utils/LocalStorage';

export default class AuthConfigBuilder {
  static get connectClientId(): string {
    return REACT_APP_AUTH_CLIENT_ID || '';
  }

  static get redirectUri(): string {
    return `${window.location.origin}?target=${window.location.pathname}`;
  }

  static get redirectUriPostLogout(): string {
    return `${window.location.origin}/landing`;
  }

  static get authUri(): string {
    const params = new URLSearchParams({
      client_id: this.connectClientId,
      scope: 'profile',
      response_type: 'code',
      redirect_uri: this.redirectUri,
      state: this.state,
    });
    return `${REACT_APP_CONNECT_ENDPOINT}?${params.toString()}`;
  }

  static get logoutUri(): string {
    const token = LocalStorage.getToken();
    if (!token) return '';
    const params = new URLSearchParams({
      id_token_hint: token,
      client_id: this.connectClientId,
      post_logout_redirect_uri: this.redirectUriPostLogout,
    });
    return `${REACT_APP_LOGOUT_ENDPOINT}?${params.toString()}`;
  }

  static get state(): string {
    if (LocalStorage.getState()) {
      return LocalStorage.getState() || '';
    }
    const { crypto } = window;
    const array = new Uint32Array(1);
    const state = crypto.getRandomValues(array).toString();
    LocalStorage.setState(state);
    return state;
  }
}
