import type { AxiosResponse } from 'axios';
import Api from '../Api';
import type { CancellationForm } from '@/types/User';

class User extends Api {
  protected getBaseUrl(): string {
    return 'users';
  }

  public get<T>(path: string): Promise<AxiosResponse<T | null>> {
    return this.axiosInstance.get<T | null>(path);
  }

  public create<T>(path: string, data: any): Promise<AxiosResponse<T | null>> {
    return this.axiosInstance.post<T | null>(path, data);
  }

  public async post(path: string, data: CancellationForm): Promise<void> {
    await this.axiosInstance.post(path, data);
  }
}

export default User;
