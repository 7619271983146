import moment from 'moment';
import { AuthConfigBuilder } from '@/core';
import { Auth } from '@/core/api';
import LocalStorage from '@/utils/LocalStorage';

export class AuthRepository {
  static async fetchToken(code: string, state: string, redirectUri: string): Promise<boolean> {
    if (state !== AuthConfigBuilder.state) {
      LocalStorage.removeToken();
      LocalStorage.removeRefreshToken();
      LocalStorage.removeExpirationDate();
      return false;
    }
    const moduleAuth = new Auth();
    return (moduleAuth.getToken(code, redirectUri).then((response) => {
      if (!response.data || !response.data.access_token) return false;
      LocalStorage.clearLanguage();
      LocalStorage.clearCategories();
      LocalStorage.setToken(response.data.access_token);
      LocalStorage.setRefreshToken(response.data.refresh_token);
      LocalStorage.setExpirationDate(response.data.expires_in - 120);
      return true;
    }).catch((e) => {
      console.error('failed request', e);
      return false;
    }));
  }

  static async fetchRefreshToken(): Promise<boolean> {
    const moduleAuth = new Auth();
    const refreshToken = LocalStorage.getRefreshToken();
    if (!refreshToken) return false;
    try {
      const loginResponse = await moduleAuth.getTokenWithRefreshToken(refreshToken);
      LocalStorage.setToken(loginResponse.data.access_token);
      LocalStorage.setRefreshToken(loginResponse.data.refresh_token);
      LocalStorage.setExpirationDate(loginResponse.data.expires_in - 120);
      return true;
    } catch (e) {
      return false;
    }
  }

  static async logOut() {
    LocalStorage.removeRefreshToken();
    LocalStorage.removeExpirationDate();
    LocalStorage.removeState();
    window.location.href = AuthConfigBuilder.logoutUri;
  }

  static isTokenValid(): boolean {
    const token : string | null = LocalStorage.getToken();
    if (!token) return false;
    const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]*$/;
    return (jwtRegex.test(token));
  }

  static isTokenNotExpired() : boolean {
    const expirationDate = LocalStorage.getExpirationDate();
    return expirationDate ? moment().isBefore(moment.unix(expirationDate)) : false;
  }

  static removeStorage() {
    LocalStorage.removeToken();
    LocalStorage.removeRefreshToken();
    LocalStorage.removeExpirationDate();
    LocalStorage.removeState();
  }

  static async getToken(): Promise<string | null> {
    const token = LocalStorage.getToken();
    if (!token && !LocalStorage.getRefreshToken()) return null;
    const expirationDate = LocalStorage.getExpirationDate();
    if (expirationDate && moment().isBefore(moment().add(expirationDate, 'seconds'))) return token;
    const fetchRefreshDone = await this.fetchRefreshToken();
    if (!fetchRefreshDone) this.removeStorage();
    return LocalStorage.getToken() || null;
  }

  static async getMemberId(): Promise<string | null> {
    const token = await this.getToken();
    if (token && this.isTokenValid() && this.isTokenNotExpired()) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64)).sub;
    }
    return null;
  }

  static signinRedirect() {
    window.location.href = AuthConfigBuilder.authUri;
  }
}
