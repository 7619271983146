import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer } from '@/components';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { RightsState } from '@/context/Rights/RightsState';

const Why: FC = () => {
  const { t } = useTranslation();
  const { isActive } = useContext(RightsContext);

  return (
    <div>
      <div className="vtmn-typo_title-4">
        {t(`cloture${isActive === RightsState.ACTIVE ? '' : '.inactive_user'}.why.title`)}
      </div>
      <Spacer vertical={2} />
      <div className="vtmn-typo_text-1">
        {t(`cloture${isActive === RightsState.ACTIVE ? '' : '.inactive_user'}.why.subtitle`)}
      </div>
    </div>
  );
};

export default Why;
