import type { LockerItem as LockerItemType, LockerItemFromBack } from '@/types/Locker';

export class LockerItemBuilder {
  public GetLockerItem(item: LockerItemFromBack) : LockerItemType {
    const indexSize = item.item_ids.findIndex((id) => id === item.item_id);
    return {
      uuid: item.uuid,
      brandLabel: item.brand_label,
      superModelCode: item.super_model_code,
      webLabel: item.web_label,
      cover: item.pack_shot[0]?.original_url,
      state: item.state,
      modelCode: item.model_code,
      itemId: item.item_id,
      supportUrl: item.support_url,
      colorLabel: item.color_label,
      price: item.price,
      validatedAt: item.validated_at,
      returnedAt: item.returned_at,
      collectedAt: item.collected_at,
      shippingAt: item.start_shipping_at,
      inPreparationAt: item.start_preparation_at,
      size: indexSize >= 0 ? item.size[indexSize] : undefined,
    };
  }
}
