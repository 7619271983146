import type { ModelFromBackNfs, StockItem } from '@/types/Model';
import { Model } from '@/core/api';
import type { ReviewsFromBack } from '@/types/Reviews';

export class ModelRepository {
  public async GetModelFromBack(modelCode: string, superModelCode: string) : Promise<ModelFromBackNfs> {
    return new Model().get(modelCode, superModelCode).then(({ data }) => data);
  }

  public async GetStockForModel(modelCode: string, superModelCode: string): Promise<StockItem[]> {
    return new Model().getStock(modelCode, superModelCode).then(({ data }) => data);
  }

  public async GetReviewsForModel(modelCode: string, superModelCode: string): Promise<ReviewsFromBack> {
    return new Model().getReviews(modelCode, superModelCode).then(({ data }) => data);
  }
}
