import type { FC } from 'react';
import { VtmnIcon } from '@vtmn/react';
import type { VtmnIconSize, VtmnIconVariant } from '@vtmn/react/dist/src/guidelines/iconography/VtmnIcon/types';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';

type Props = {
  size?: VtmnIconSize,
  value: VitamixId,
  variant?: VtmnIconVariant,
  className?: string,
  onClick?: () => void,
};

const Icon: FC<Props> = ({
  size, value, variant, className, onClick,
}) => (
  <VtmnIcon
    size={size}
    value={value}
    variant={variant}
    className={className}
    onClick={onClick}
  />
);

export default Icon;
