import type { AxiosResponse } from 'axios';
import type { ModelFromBackNfs, StockItem } from '@/types/Model';
import type { ReviewsFromBack } from '@/types/Reviews';
import Api from '../Api';

class Model extends Api {
  protected getBaseUrl(): string {
    return 'models/';
  }

  public get(modelCode: string, superModelCode: string): Promise<AxiosResponse<ModelFromBackNfs>> {
    return this.axiosInstance.get<ModelFromBackNfs>(`${superModelCode}/${modelCode}`, {});
  }

  public getStock(modelCode: string, superModelCode: string): Promise<AxiosResponse<StockItem[]>> {
    return this.axiosInstance.get<StockItem[]>(`${superModelCode}/${modelCode}/stock`, {});
  }

  public getReviews(modelCode: string, superModelCode: string): Promise<AxiosResponse<ReviewsFromBack>> {
    return this.axiosInstance.get<ReviewsFromBack>(`${superModelCode}/${modelCode}/reviews`, {});
  }
}

export default Model;
