import classNames from 'classnames';
import type { FC } from 'react';
import { useContext } from 'react';
import classes from './styles.module.scss';
import { Spacer } from '@/components';
import { LockerContext } from '@/context/Locker/LockerProvider';

type NavTabProps = {
  id: number;
  label: string;
  isSelected: boolean;
  iconPath: string;
  type: string,
  onClick: () => void;
};

const NavTab: FC<NavTabProps> = ({
  id,
  label,
  iconPath,
  isSelected,
  onClick,
  type,
}) => {
  const { nbItemsInLocker } = useContext(LockerContext);

  return (
    <button
      tabIndex={isSelected ? 0 : -1}
      id={`nav-bar-${id}`}
      key={id}
      aria-controls={`tabpanel-${id}`}
      onClick={onClick}
      className={classes.tab}
    >
      <div className={classes.icon_content}>
        { type === 'main' && nbItemsInLocker > 0
          && (
          <div className={classNames('vtmn-typo_caption-1 vtmn-font-bold', classes.nb_items)}>
            {nbItemsInLocker}
          </div>
          )}
        <img
          alt="icon"
          className={classes.icon}
          src={iconPath}
        />
      </div>
      <Spacer vertical={0.5} />
      <span className={classNames('vtmn-typo_caption-1', {
        [classes.selected]: isSelected,
        'vtmn-font-bold': isSelected,
      })}
      >
        {label}
      </span>
    </button>
  );
};

export default NavTab;
