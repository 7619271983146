import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useTracking } from '@/hooks/useTracking';
import { ContentPageTitle, BigButton } from '@/components';
import classes from './styles.module.scss';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { ConfigContext } from '@/context/Config/ConfigProvider';

type Props = {
  displayBackButton?: boolean,
};

const Help: FC<Props> = ({ displayBackButton }) => {
  const { landing } = useContext(ConfigContext);
  const { t, i18n } = useTranslation();
  const { clickEvent } = useTracking();

  const help = () => {
    clickEvent(MATOMO_CATEGORY.HELP_PAGE.CONTACT);
    const link = landing?.help_links.find((x) => x.language === i18n.language)
      || landing?.help_links[0];
    if (link) {
      window.open(link.url, '_blank');
    }
  };

  const openMailBox = () => {
    window.open('mailto:team.wpc@decathlon.com', '_blank');
  };

  return (
    <div>
      <ContentPageTitle displayBackButton={displayBackButton}>
        {t('contact.title')}
      </ContentPageTitle>
      <div className={classes.sections}>
        <h3>{t('contact.subtitle')}</h3>
        <BigButton text={t('contact.phone.number')} leftIcon="phone-line">
          {t('contact.phone.description')}
        </BigButton>
        <BigButton
          onClick={() => { openMailBox(); }}
          text={t('contact.email')}
          leftIcon="mail-send-line"
        />
        <BigButton
          onClick={help}
          text={t('contact.store.label')}
          leftIcon="store-line"
        />
      </div>
    </div>
  );
};

export default Help;
