import UseCaseInterface from '@/usecases/UseCaseInterface';
import { ConfigRepository } from '@/repositories';
import SessionStorage from '@/utils/SessionStorage';

class GetBanCategoriesUseCase extends UseCaseInterface<void, string[]> {
  async execute(): Promise<string[]> {
    const saved = SessionStorage.getBanCategories();
    if (!saved) {
      const ban = await ConfigRepository.getBanCategories();
      SessionStorage.setBanCategories(JSON.stringify(ban));
      return ban;
    }
    return JSON.parse(saved);
  }
}

export default new GetBanCategoriesUseCase();
