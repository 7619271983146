import type { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';

type Props = {
  cookieTitle: string,
};

const Head: FC<Props> = ({ cookieTitle }) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr className={classes.title_row}>
        <td
          colSpan={4}
          className={classNames('vtmn-typo_title-5 vtmn-font-normal', classes.title)}
        >
          {cookieTitle}
        </td>
      </tr>
      <tr className={classNames('vtmn-typo_text-1 vtmn-font-bold', classes.subtitle_row)}>
        <th className={classes.subtitle}>
          { t('cookies.details.tab_titles.tag_solution') }
        </th>
        <th className={classes.subtitle}>
          { t('cookies.details.tab_titles.objectif') }
        </th>
        <th className={classes.subtitle}>
          { t('cookies.details.tab_titles.name') }
        </th>
        <th className={classes.subtitle}>
          { t('cookies.details.tab_titles.lifetime') }
        </th>
      </tr>
    </thead>
  );
};

export default Head;
