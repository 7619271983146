import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import {
  Spacer, CardsService, Welcome, Help,
  NextStepCloture, DataExportCloture,
} from '@/components';
import Why from '@/pages/Cloture/Why';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { RightsState } from '@/context/Rights/RightsState';

const Cloture: FC = () => {
  const { t } = useTranslation();
  const { isActive } = useContext(RightsContext);

  return (
    <div className={classes.content}>
      <div className={classes.root}>
        <Welcome titleWelcome={t(`cloture.${isActive === RightsState.ACTIVE ? '' : 'inactive_user.'}title`)} displayHi={false} />
        <Spacer vertical={6} />
        <Why />
        <Spacer vertical={6} />
        {isActive === RightsState.ACTIVE
          && (
            <>
              <NextStepCloture />
              <Spacer vertical={6} />
              <DataExportCloture />
              <Spacer vertical={6} />
            </>
          )}
        <CardsService />
        <Spacer vertical={6} />
        <Help displayBackButton={false} />
      </div>
    </div>
  );
};

export default Cloture;
