import { createContext, useMemo, useState } from 'react';

export type CategoryContextType = {
  goToFirstCategory: boolean,
  setGoToFirstCategory: (updateFirstCategory: boolean) => void;
};

export const CategoryContext = createContext<CategoryContextType>({
  goToFirstCategory: false,
  setGoToFirstCategory: () => null,
});

export const CategoryProvider = ({ children }: { children: JSX.Element }) => {
  const [goToFirstCategory, setGoToFirstCategory] = useState<boolean>(false);

  const value = useMemo(() => ({
    goToFirstCategory,
    setGoToFirstCategory,
  }), [goToFirstCategory, setGoToFirstCategory]);

  return <CategoryContext.Provider value={value}>{ children }</CategoryContext.Provider>;
};
