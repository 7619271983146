import UseCaseInterface from '@/usecases/UseCaseInterface';
import { BundleRepository } from '@/repositories';
import { BundlerBuilder } from '@/builder/BundlerBuilder';
import type { Bundle } from '@/types/Bundle';

class GetBundleByModelCodeUseCase extends UseCaseInterface<string, Bundle | undefined> {
  async execute(modelCode: string): Promise<Bundle | undefined> {
    const bundle = await BundleRepository.getBundleByModelCode(modelCode);
    if (!bundle) return;
    const builderBundle = new BundlerBuilder();

    return (await builderBundle.builderBundle([bundle]))[0];
  }
}

export default new GetBundleByModelCodeUseCase();
