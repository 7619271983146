import { createRoot } from 'react-dom/client';
import './index.css';
import './i18n';
import '@vtmn/css/dist/index.css';
import '@vtmn/icons/dist/vitamix/font/vitamix.css';
import { StrictMode } from 'react';
import App from './App';

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
