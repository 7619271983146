import type { FC } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@/hooks/useResponsive';
import { Spacer, WPCButton } from '@/components';
import { ROUTES } from '@/pages/routes';
import classes from './styles.module.scss';
import { CategoryContext } from '@/context/Category/CategoryProvider';
import Assets from '@/core/api/Assets';

const CatalogProductSmallImg = Assets.getAssets('/catalogProduct/catalogProductSmallImg.svg');
const CatalogProductBigImg = Assets.getAssets('/catalogProduct/catalogProductBigImg.svg');

const CatalogProduct: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const navigateTo = useNavigate();
  const { setGoToFirstCategory } = useContext(CategoryContext);

  const goToSeeAllProduct = () => {
    if (!isMobile) {
      setGoToFirstCategory(true);
      navigateTo(ROUTES.HOME_PAGE);
    } else navigateTo(ROUTES.SEARCH);
  };

  return (
    <div className={classes.root}>
      <img src={isMobile ? CatalogProductSmallImg : CatalogProductBigImg} alt="CatalogProduct" />
      <div className={classes.right_side}>
        <div className={isMobile ? 'vtmn-typo_title-5' : 'vtmn-typo_title-4'}>
          { t('product_catalog.title') }
        </div>
        <Spacer vertical={0.5} />
        <div className={isMobile ? 'vtmn-typo_text-3' : 'vtmn-typo_text-2'}>
          { t('product_catalog.subtitle') }
        </div>
        <Spacer vertical={isMobile ? 4 : 8} />
        <button className={classes.button}>
          <WPCButton
            size={isMobile ? 'stretched' : 'medium'}
            variant="primary"
            onAction={goToSeeAllProduct}
          >
            { t('product_catalog.see_product') }
          </WPCButton>
        </button>
      </div>
    </div>
  );
};

export default CatalogProduct;
