import type React from 'react';
import classes from './styles.module.scss';
import { Icon } from '@/components';

type BackButtonProps = {
  onBack: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({ onBack }) => (
  <button className={classes.root} onClick={onBack}>
    <Icon
      value="arrow-left-line"
      size={16}
      variant="default"
    />
  </button>
);

export default BackButton;
