import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import classes from './styles.module.scss';
import { Spacer, WPCButton } from '@/components';
import { ROUTES } from '@/pages/routes';
import Assets from '@/core/api/Assets';

const cloture = Assets.getAssets('/cloture/cloture.svg');

const Community: FC = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <div className="vtmn-typo_title-4">
          {t('homepage.cloture.community.title')}
        </div>
        <Spacer vertical={2} />
        <div className="vtmn-typo_text-1">
          {t('homepage.cloture.community.description_after_6_may')}
        </div>
        <Spacer vertical={2} />
        <WPCButton
          variant="primary"
          onAction={() => { navigateTo(ROUTES.LOCKER); }}
        >
          {t('actions.restitution_product')}
        </WPCButton>
      </Grid>
      <Grid item xs={12} md={6}>
        <img className={classes.img} src={cloture} alt="cloture" />
      </Grid>
    </Grid>
  );
};

export default Community;
