import type { FC } from 'react';
import classNames from 'classnames';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@/hooks/useResponsive';
import classes from './styles.module.scss';
import { Spacer, WPCButton } from '@/components';
import { ROUTES } from '@/pages/routes';
import { CategoryContext } from '@/context/Category/CategoryProvider';
import Assets from '@/core/api/Assets';

const NoSelection = Assets.getAssets('/locker/noSelection.svg');

const SelectionEmpty : FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const navigateTo = useNavigate();
  const { setGoToFirstCategory } = useContext(CategoryContext);

  const openCategories = useCallback(() => {
    if (isMobile) {
      navigateTo(ROUTES.SEARCH);
      return;
    }
    setGoToFirstCategory(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <div className={classes.empty_info}>
        <img src={NoSelection} alt="NoSelection" />
        {!isMobile && <Spacer horizontal={10} /> }
        <div className={classes.text_button}>
          <div className={classNames('vtmn-typo_text-2', classes.empty_text)}>
            { t('locker.selection.empty.no_product') }
          </div>
          <Spacer vertical={1} />
          <WPCButton
            onAction={() => { openCategories(); }}
            variant="primary"
            size={isMobile ? 'stretched' : 'medium'}
          >
            {t('actions.see_catalogue')}
          </WPCButton>
        </div>
      </div>
    </div>
  );
};

export default SelectionEmpty;
