import { useCallback } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { MATOMO_ACTION } from '@/constants/matomo';

export const useTracking = () => {
  const { trackEvent, trackPageView } = useMatomo();

  const clickEvent = useCallback((category: string) => {
    if (REACT_APP_ENV === 'prod') {
      trackEvent({ category, action: MATOMO_ACTION.CLICK });
    }
  }, [trackEvent]);

  const viewEvent = useCallback((documentTitle: string) => {
    if (REACT_APP_ENV === 'prod') {
      trackPageView({ documentTitle });
    }
  }, [trackPageView]);

  const backEndEvent = useCallback((category: string) => {
    if (REACT_APP_ENV === 'prod') {
      trackEvent({ category, action: MATOMO_ACTION.BACK_END });
    }
  }, [trackEvent]);

  return {
    clickEvent,
    viewEvent,
    backEndEvent,
  };
};
