import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@/hooks/useResponsive';
import Desktop from './Desktop';
import Mobile from './Mobile';
import classes from './styles.module.scss';
import type { CookieContent } from '@/types/Config';

type DetailsProps = {
  content : CookieContent
};
const Details: FC<DetailsProps> = ({ content }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div>
      <span className="vtmn-typo_title-4">
        { t('cookies.details.title') }
      </span>
      <Grid container spacing={3} className={classes.cookies_block}>
        { content.tab_values.map((value, index) => (
          <Grid item xs={12} key={index}>
            { isMobile ? <Mobile cookies={value} /> : <Desktop cookies={value} /> }
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Details;
