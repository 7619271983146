import { User } from '@/core/api';
import { LocaleRepository } from '@/repositories/LocaleRepository';
import type { UserStoreAndSubscription } from '@/types/User';

const SUBSCRIPTION_TYPE: { [key: string]: string } = {
  SMALL: 'SMALL',
  MEDIUM: 'BIG',
  BIG: 'DREAM',
};
export class UserRepository {
  static async getUserName(): Promise<string | null> {
    return new User().get<string>('/name').then(({ data }) => data);
  }

  public async getUserGauge() {
    return new User().get<any>('/gauge').then(({ data, status }) => {
      if (status === 200) {
        return data;
      }

      throw new Error();
    });
  }

  public async getUserStoreAndSubcription(): Promise<UserStoreAndSubscription> {
    return new User().get<any>('/storeAndSubscription').then(({ data }) => data);
  }

  public async getUserRights() {
    return new User().get<any>('/rights').then(({ data }) => data);
  }

  public async getNbPlacesAvailableCase(): Promise<number> {
    return new User().get<any>('/max_users_reach').then(({ data }) => data);
  }

  public async cancellation(data: any): Promise<void> {
    await new User().post('/cancellation', data);
  }

  static async createUser(store: string, subscription: string) {
    return new User()
      .create<any>('/', {
      store,
      subscription_type: subscription,
    })
      .then((response) => {
        if (response.status === 201) {
          const subscriptionType = SUBSCRIPTION_TYPE[response.data.subscription_type];
          LocaleRepository.getCountry()
            .then((locale) => {
              const lng = locale.languages.slice(0, 5);
              // eslint-disable-next-line max-len
              window.location.href = `${REACT_APP_ZIQY}?member_id=${response.data.member_uuid}&page=ORDER&subscription_type=${subscriptionType}&return_url=${REACT_APP_WPC_APP}&accept_language=${lng}`;
            });
          return;
        }
        throw new Error();
      });
  }
}
