import type { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classes from './styles.module.scss';
import {
  WPCButton, Spacer, ModalActions, ModalVtmn,
} from '@/components';
import { useResponsive } from '@/hooks/useResponsive';
import Assets from '@/core/api/Assets';

const GoodJob = Assets.getAssets('/general/goodJob.svg');

type Props = {
  onClose: () => void,
  isOpen: boolean,
};
const EmailSave: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <ModalVtmn open={isOpen} onClose={onClose} classNameTitle="vtmn-typo_title-1" title={t('landing.email_save.title')}>
      <> {!isMobile && <Spacer vertical={2} /> } </>
      <div className={classes.root}>
        <img src={GoodJob} alt="GoodJob" className={classes.img} />
        <Spacer vertical={3} />
        <div>
          <div className="vtmn-typo_title-5"> { t('landing.email_save.subtitle') }</div>
          <Spacer vertical={2} />
          <Trans i18nKey="landing.email_save.description" />
        </div>
      </div>
      <> {isMobile && <Spacer vertical={3} /> } </>
      <ModalActions className={classes.button}>
        <WPCButton
          variant="primary"
          onAction={onClose}
        >
          { t('actions.ok') }
        </WPCButton>
      </ModalActions>
    </ModalVtmn>
  );
};

export default EmailSave;
