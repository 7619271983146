import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

type ContentPageSubTitleProps = {
  children: JSX.Element | string | JSX.Element[]
};

const ContentPageSubTitle: FC<ContentPageSubTitleProps> = ({ children }) => (
  <div className={classNames('vtmn-font-bold vtmn-typo_title-5', classes.root)}>
    {children}
  </div>
);

export default ContentPageSubTitle;
