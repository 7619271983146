import type { AxiosResponse } from 'axios';
import Api from '@/core/api/Api';

class StaticFiles extends Api {
  protected getBaseUrl(): string {
    return 'static_files/';
  }

  public async getStaticFiles(fileType: string): Promise<AxiosResponse> {
    return this.axiosInstance.get(`?fileType=${fileType}`);
  }
}

export default StaticFiles;
