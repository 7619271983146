import UseCaseInterface from '@/usecases/UseCaseInterface';
import { ConfigRepository } from '@/repositories';
import SessionStorage from '@/utils/SessionStorage';
import type { LandingContent } from '@/types/Config';

class GetLandingUseCase extends UseCaseInterface<any, LandingContent> {
  async execute(): Promise<LandingContent> {
    const saved = SessionStorage.getLanding();
    if (!saved) {
      const landing = await ConfigRepository.getLanding();
      SessionStorage.setLanding(JSON.stringify(landing));
      return landing;
    }
    return JSON.parse(saved);
  }
}
export default new GetLandingUseCase();
