import type { FC } from 'react';
import { ModalVtmn } from '@/components';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  title: string,
  children: JSX.Element | string,
};

const PopUp: FC<Props> = ({
  isOpen, onClose, children, title,
}) => (
  // eslint-disable-next-line react/jsx-no-bind
  <ModalVtmn open={isOpen} onClose={onClose} title={title}>
    <div> {children} </div>
  </ModalVtmn>
);

export default PopUp;
