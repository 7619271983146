import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '@/hooks/useTracking';
import classes from './styles.module.scss';
import {
  WPCButton, Drawer, PromotionalPriceAndPrice, Icon,
} from '@/components';
import { LOCKER_STATE } from '@/constants/locker';
import type { LockerItem } from '@/types/Locker';
import { ROUTES } from '@/pages/routes';
import Status from './status';
import { useResponsive } from '@/hooks/useResponsive';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import Assets from '@/core/api/Assets';

const ToolsIcon = Assets.getAssets('/icon/tools.svg');

type ModalProps = {
  selectedProduct: LockerItem | null,
  setSelectedProduct: Dispatch<SetStateAction<LockerItem | null>>,
  setIsOpen: (isOpen: boolean) => void,
  isOpen: boolean,
};

const Modal: FC<ModalProps> = ({
  selectedProduct,
  setSelectedProduct,
  setIsOpen,
  isOpen,
}) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { isMobile } = useResponsive();
  const { clickEvent } = useTracking();

  const goToProductPage = useCallback(() => {
    if (!selectedProduct) return;
    clickEvent(MATOMO_CATEGORY.LOCKER.OVERLAY_HISTORY);
    navigateTo(`${ROUTES.PRODUCT.replace(':model_code', selectedProduct.modelCode).replace(':super_model_code', selectedProduct.superModelCode)}`);
  }, [navigateTo, selectedProduct, clickEvent]);

  const openSupport = useCallback(() => {
    if (!selectedProduct) return;
    clickEvent(MATOMO_CATEGORY.LOCKER.SUPPORT_PRODUCT);
    window.open(selectedProduct.supportUrl);
  }, [clickEvent, selectedProduct]);

  const close = () => {
    setIsOpen(false);
    setSelectedProduct(null);
  };

  return (
    <Drawer
      isOpen={isOpen}
      updateDrawer={setIsOpen}
      anchor={isMobile ? 'bottom' : 'right'}
      height={'{xs: "100%"}'}
    >
      <div className={classes.root_drawer}>
        {selectedProduct && (
          <>
            <div className={classes.header}>
              <img src={selectedProduct.cover} width={275} height={275} alt="" />
              <button className={classes.close} onClick={close}>
                <Icon
                  size={24}
                  value="close-line"
                  variant="default"
                />
              </button>
            </div>
            <div className={classes.information}>
              <div className="vtmn-font-bold vtmn-typo_text-2">{selectedProduct.brandLabel}</div>
              <div className="vtmn-font-bold vtmn-typo_title-5">{selectedProduct.webLabel}</div>
            </div>
            <div className={classes.price}>
              <PromotionalPriceAndPrice realPrice={selectedProduct.price} />
            </div>
            <div className={classes.states}>
              <Status
                icon="bill-line"
                variantIcon="default"
                trad="locker.validated_at"
                date={selectedProduct.validatedAt}
              />
              {selectedProduct.inPreparationAt
                && (
                  <Status
                    icon="refresh-line"
                    variantIcon="positive"
                    trad="locker.in_preparation_at"
                    date={selectedProduct.inPreparationAt}
                  />
                )}
              {selectedProduct.shippingAt
                && (
                  <Status
                    icon="truck-line"
                    variantIcon="positive"
                    trad="locker.shipping_at"
                    date={selectedProduct.shippingAt}
                  />
                )}
              {selectedProduct.state === LOCKER_STATE.TO_COLLECT
                && (
                <div>
                  <div>
                    <Icon
                      size={16}
                      value="check-line"
                      variant="positive"
                    />
                  </div>
                  <div className={classes.to_collect}>
                    <Icon
                      size={16}
                      value="information-line"
                      variant="warning"
                    />
                    <span>{t('locker.state.to_collect')}</span>
                  </div>
                </div>
                )}
              { selectedProduct.collectedAt
                && (
                  <Status
                    icon="check-line"
                    variantIcon="positive"
                    trad="locker.collected_at"
                    date={selectedProduct.collectedAt}
                  />
                )}
              {selectedProduct.returnedAt
                && (
                <Status
                  icon="arrow-go-back-line"
                  variantIcon="negative"
                  trad="locker.returned_at"
                  date={selectedProduct.returnedAt}
                />
                )}
            </div>
            { selectedProduct.supportUrl
              && (
                <div className={classes.button}>
                  <button className={classes.support} onClick={openSupport}>
                    <div>
                      <img src={ToolsIcon} alt="" width={15} height={15} />
                      <span className="vtmn-font-bold vtmn-typo_text-2">
                        { t('locker.cta.support') }
                      </span>
                    </div>
                    <Icon
                      size={20}
                      value="external-link-line"
                      variant="default"
                    />
                  </button>
                </div>
              )}
            <WPCButton
              onAction={() => { goToProductPage(); }}
              size="stretched"
              variant="primary"
              iconRight="chevron-right-line"
            >
              { t('locker.cta.product_page') }
            </WPCButton>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default Modal;
