import { useEffect, useState } from 'react';
import type { FC } from 'react';
import i18n from 'i18next';
import { ContentPage } from '@/components';

import GetStaticFileUseCase from '@/usecases/GetStaticFileUseCase';
import type { StaticFile } from '@/types/StaticFile';
import { StaticFiles } from '@/constants/staticFiles';

const CGA: FC = () => {
  const [cga, setCga] = useState<StaticFile>();

  useEffect(() => {
    (async () => {
      const data = await GetStaticFileUseCase.execute(StaticFiles.CGA);
      setCga(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return cga ? (
    <ContentPage content={{ title: cga.title, content: cga.content }} />
  ) : null;
};

export default CGA;
