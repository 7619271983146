import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

type ContentPageParagraphProps = {
  children: JSX.Element | string | JSX.Element[]
};

const ContentPageParagraph: FC<ContentPageParagraphProps> = ({ children }) => (
  <div className={classNames('vtmn-typo_text-2 vtmn-font-normal', classes.root)}>
    {children}
  </div>
);

export default ContentPageParagraph;
