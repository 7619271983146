import { createInstance } from '@jonkoops/matomo-tracker-react';

class Matomo {
  createMatomoInstance() {
    return createInstance({
      urlBase: `${REACT_APP_WPC_APP}`,
      siteId: 1,
      trackerUrl: 'https://weplaycircular.matomo.cloud/matomo.php',
      srcUrl: '//cdn.matomo.cloud/weplaycircular.matomo.cloud/matomo.js',
    });
  }
}

export default new Matomo();
