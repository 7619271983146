import type { FC } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import ExpandButton from './ExpandButton';

type AccordionProps = {
  open?: boolean,
  isFirst?: boolean,
  isLast?: boolean,
  summary: string | JSX.Element | JSX.Element[],
  children: JSX.Element | string | JSX.Element[]
};

const Accordion: FC<AccordionProps> = ({
  open = false, isFirst, isLast, summary, children,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <div className={classNames(classes.root, {
      [classes.no_border]: isLast,
      [classes.rounded_top]: isFirst,
      [classes.rounded_bottom]: isLast,
    })}
    >
      <button className={classes.header} onClick={() => setIsOpen(!isOpen)}>
        <div className={classNames('vtmn-font-bold vtmn-typo_text-2 vtmn-text-black', classes.summary)}>{summary}</div>
        <div><ExpandButton expanded={isOpen} /></div>
      </button>
      <div className={classNames(classes.content, { [classes.open]: isOpen })}>{children}</div>
    </div>
  );
};

export default Accordion;
