import { BrowserRouter as Router } from 'react-router-dom';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import type { FC } from 'react';
import Matomo from '@/service/Matomo/matomo';
import AppContent from './AppContent';
import { RightsProvider } from '@/context/Rights/RightsProvider';
import { AuthProvider } from './context/Auth/AuthProvider';
import { PricesProvider } from './context/Filters/PricesContext';
import { SizesProvider } from './context/Filters/SizesContext';
import { SortPriceProvider } from './context/Filters/SortPriceContext';
import { FiltersProvider } from './context/Filters/FiltersContext';
import { ReturnProvider } from '@/context/Return/ReturnProvider';
import { ErrorManagementProvider } from '@/context/ErrorManagement/ErrorManagementProvider';
import { LanguageProvider } from '@/context/Language/LanguageProvider';
import { ConfigProvider } from '@/context/Config/ConfigProvider';

const App: FC = () => (
  <Router>
    <ErrorManagementProvider>
      <MatomoProvider value={Matomo.createMatomoInstance()}>
        <ConfigProvider>
          <AuthProvider>
            <RightsProvider>
              <LanguageProvider>
                <FiltersProvider>
                  <PricesProvider>
                    <SizesProvider>
                      <SortPriceProvider>
                        <ReturnProvider>
                          <AppContent />
                        </ReturnProvider>
                      </SortPriceProvider>
                    </SizesProvider>
                  </PricesProvider>
                </FiltersProvider>
              </LanguageProvider>
            </RightsProvider>
          </AuthProvider>
        </ConfigProvider>
      </MatomoProvider>
    </ErrorManagementProvider>
  </Router>
);
export default App;
