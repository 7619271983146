import type { Locale } from '@/types/Locale';

class LocaleApi {
  public getCountry(): Locale {
    const lang = window.navigator.language.split('-')[0];
    if (lang !== 'fr' && lang !== 'nl') return { country: 'BE', languages: 'fr' };
    return {
      country: 'BE',
      languages: lang,
    };
  }
}
export default new LocaleApi();
