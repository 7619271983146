import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@/hooks/useResponsive';
import Mobile from './Mobile';
import Desktop from './Desktop';
import classes from './styles.module.scss';
import Assets from '@/core/api/Assets';

const Thermometer = Assets.getAssets('/icon/thermometer.svg');
const Gear = Assets.getAssets('/icon/gear.svg');

const WhatIs: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <Grid container>
      <Grid item xs={12} className="vtmn-typo_title-4">
        { t('cookies.what_is.title') }
      </Grid>
      <Grid item xs={12} className="vtmn-typo_text-2">
        { t('cookies.what_is.description') }
      </Grid>
      <Grid item xs={12}>
        {isMobile ? (
          <div>
            <Mobile traduction="necessary" icon={Gear} disabled={false} />
            <Mobile
              traduction="not_necessary"
              icon={Thermometer}
              className={classes.mobile_not_necessary}
              disabled
            />
          </div>
        ) : (
          <Desktop />
        )}
      </Grid>
    </Grid>
  );
};

export default WhatIs;
