import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Spacer, Icon } from '@/components';
import classes from './styles.module.scss';

type Props = {
  closeOverlay?: () => void,
  search: string,
  displayCloseIcon: boolean,
};

export const NoResult: FC<Props> = ({ closeOverlay, search, displayCloseIcon }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={displayCloseIcon ? 11 : 12}>
        <div className="vtmn-font-bold"> {t('research.error.title', { search }) } </div>
        <Spacer vertical={1} />
        <div> {t('research.error.subtitle') } </div>
      </Grid>
      { displayCloseIcon
        && (
          <Grid item xs={1} className={classes.text_right}>
            <Icon
              className={classes.icon_close}
              onClick={closeOverlay}
              size={32}
              variant="brand"
              value="close-fill"
            />
          </Grid>
        )}
    </Grid>
  );
};

export default NoResult;
