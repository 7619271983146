import type { JwtPayload } from 'jwt-decode';
// eslint-disable-next-line import/no-extraneous-dependencies
import { jwtDecode } from 'jwt-decode';

interface JwtPayloadDkt extends JwtPayload {
  origin?: string,
  sub?: string,
}
class BearerToken {
  static decode(token: string | JwtPayload): JwtPayloadDkt {
    if (token instanceof Object) {
      return token;
    }
    const decoded = jwtDecode(token);
    return decoded || {};
  }
}

export default BearerToken;
