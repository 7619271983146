import type { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useResponsive } from '@/hooks/useResponsive';
import classes from './styles.module.scss';
import { Spacer, WPCButton } from '@/components';
import LocaleStorage from '@/utils/LocalStorage';
import { ROUTES } from '@/pages/routes';
import Assets from '@/core/api/Assets';

const SurfBigImg = Assets.getAssets('/subscribe/surfBigImg.svg');
const SurfSmallImg = Assets.getAssets('/subscribe/surfSmallImg.svg');
const Subscription: FC = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  const getLink = (): string => {
    const lng = LocaleStorage.getLng();
    return lng === 'fr' ? 'https://docs.google.com/forms/d/e/1FAIpQLSdpPCOGohe6Luo4q4wsCZcbTiPY-i4Tt1Q4rmlg2J31VXMPnw/viewform?usp=sf_link'
      : 'https://docs.google.com/forms/d/e/1FAIpQLSd6CSxo8boxVFb2KXVAZ3LJDxAmgV4jxP8CAt39sVCfkQxJ9A/viewform?usp=sf_link';
  };

  return (
    <div className={classes.root}>
      <img src={isMobile ? SurfSmallImg : SurfBigImg} alt="Surf" className={classes.img} />
      <Spacer vertical={isMobile ? 3 : 4} />
      <div className={isMobile ? 'vtmn-typo_title-3' : 'vtmn-typo_title-4'}>
        { t('subscribe.subscription.title') }
      </div>
      <Spacer vertical={isMobile ? 3 : 4} />
      <Trans i18nKey="subscribe.subscription.subtitle" />
      <Spacer vertical={isMobile ? 3 : 4} />
      <div className={classes.buttons}>
        <Link to={ROUTES.SUBSCRIPTION_SELECTION}>
          <WPCButton
            onAction={() => {}}
            className={classes.button}
            size={isMobile ? 'stretched' : 'medium'}
            variant="primary"
          >
            { t('subscribe.subscription.discover') }
          </WPCButton>
        </Link>
        <Link to={getLink()} target="_blank">
          <WPCButton
            onAction={() => {}}
            className={classes.button}
            size={isMobile ? 'stretched' : 'medium'}
            variant="secondary"
          >
            { t('subscribe.subscription.ask') }
          </WPCButton>
        </Link>
      </div>
    </div>
  );
};

export default Subscription;
