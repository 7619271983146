import UseCaseInterface from '@/usecases/UseCaseInterface';
import { UserRepository } from '@/repositories';

class GetNbPlacesAvailableCase extends UseCaseInterface<any, number> {
  async execute(): Promise<number> {
    const userRepository = new UserRepository();
    return userRepository.getNbPlacesAvailableCase();
  }
}

export default new GetNbPlacesAvailableCase();
