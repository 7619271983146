import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { VtmnLoader } from '@vtmn/react';
import classes from './styles.module.scss';
import {
  Spacer, CardsService,
} from '@/components';
import CatchLineCloture from './CatchLineCloture';
import WhatIs from './WhatIs';
import { ConfigContext } from '@/context/Config/ConfigProvider';
import { ROUTES } from '@/pages/routes';
import { AuthState } from '@/context/Auth/AuthState';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { RightsState } from '@/context/Rights/RightsState';
import GetStaticFileUseCase from '@/usecases/GetStaticFileUseCase';
import { StaticFiles } from '@/constants/staticFiles';

const Landing: FC = () => {
  const { landing } = useContext(ConfigContext);
  const { isActive } = useContext(RightsContext);
  const { isAuth } = useContext(AuthContext);
  const [promoCode, setPromoCode] = useState<string>('');
  const { i18n } = useTranslation();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) navigateTo(ROUTES.HOME_PAGE);
  }, [isActive, navigateTo, isAuth]);

  useEffect(() => {
    (async () => {
      const promo = await GetStaticFileUseCase.execute(StaticFiles.PROMO_CODE);
      setPromoCode(promo.content);
    })();
  }, [i18n.language]);

  const checkForEmptyTags = (tags: any): boolean => {
    if (!tags) return false;
    const EMPTY_TAGS_REGEX = /<[^>]*>s*<\/[^>]*>/;
    return EMPTY_TAGS_REGEX.test(tags);
  };

  if (isAuth === AuthState.LOADING || isActive === RightsState.LOADING) {
    return <div className={classes.loader}><VtmnLoader size="large" /></div>;
  }

  return (landing
    ? (
      <div className={classes.root}>
        <Spacer vertical={1} />
        <div className={classes.content}>
          {!checkForEmptyTags(promoCode) && (
            <div className={classes.promo_code_container}>
              <div className={classes.promo_code}>{parse(promoCode)}</div>
            </div>
          )}
          <Spacer vertical={4} />
          <CatchLineCloture />
          <Spacer vertical={4} />
          <WhatIs />
          <Spacer vertical={2} />
          <CardsService className={classes.container} />
        </div>
      </div>
    ) : null
  );
};

export default Landing;
