import type { FC } from 'react';
import { Grid } from '@mui/material';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import { useTranslation } from 'react-i18next';
import { Spacer, Icon } from '@/components';
import classes from './styles.module.scss';

const NextStep: FC = () => {
  const { t } = useTranslation();

  const nextStep = [
    { step: 'first', icon: 'calendar-date-fill' as VitamixId },
    { step: 'second', icon: 'calendar-check-fill' as VitamixId },
    { step: 'third', icon: 'arrow-left-right-fill' as VitamixId },
  ];

  return (
    <div>
      <div className="vtmn-typo_title-4">
        {t('cloture.next_step.title')}
      </div>
      <Spacer vertical={2} />
      <Grid container spacing={3}>
        { nextStep.map((step, index) => (
          <Grid item xs={12} md={4} key={index}>
            <div className={classes.card}>
              <div className={classes.icon}>
                <Icon value={step.icon} size={32} variant="brand" />
              </div>
              <Spacer vertical={1} />
              <div className="vtmn-typo_title-5">
                {t(`cloture.next_step.${step.step}.title`)}
              </div>
              <Spacer vertical={1} />
              <div className="vtmn-typo_text-2">
                {t(`cloture.next_step.${step.step}.description`)}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default NextStep;
