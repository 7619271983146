import UseCaseInterface from '@/usecases/UseCaseInterface';
import { UserRepository } from '@/repositories';
import type { CancellationForm } from '@/types/User';

class CancellationUseCase extends UseCaseInterface<CancellationForm, void> {
  async execute(data: CancellationForm): Promise<void> {
    const userRepository = new UserRepository();
    await userRepository.cancellation(data);
  }
}

export default new CancellationUseCase();
