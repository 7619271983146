import type { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import CookieAccordion from './CookieAccordion';
import type { CookiesTitleContent } from '@/types/Config';

type Props = {
  cookies: CookiesTitleContent;
};

const Mobile: FC<Props> = ({ cookies }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={classNames('vtmn-font-bold vtmn-typo_text-1', classes.title)}>
        { cookies.title }
      </div>
      <div className={classNames('vtmn-font-bold vtmn-typo_text-1', classes.subtitle)}>
        { t('cookies.details.tab_titles.tag_solution') }
      </div>
      { cookies.cookies.map((cookie, index) => (
        <CookieAccordion cookie={cookie} key={index} />
      ))}
    </div>
  );
};

export default Mobile;
