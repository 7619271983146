import type { AxiosResponse } from 'axios';
import Api from '../../Api';
import type { LockerItemFromBack, ReturnItem } from '@/types/Locker';

export default class Locker extends Api {
  protected getBaseUrl(): string {
    return 'locker';
  }

  public getEquipment(): Promise<AxiosResponse<LockerItemFromBack[] | []>> {
    return this.axiosInstance.get<LockerItemFromBack[] | []>('/pending_products');
  }

  public getMyLocker(): Promise<AxiosResponse<LockerItemFromBack[] | []>> {
    return this.axiosInstance.get<LockerItemFromBack[] | []>('/equipments');
  }

  public returnEquipments(items: ReturnItem[]): Promise<AxiosResponse<any>> {
    return this.axiosInstance.patch('/equipments', items);
  }

  public validateReturn(items: string[]): Promise<AxiosResponse<void>> {
    return this.axiosInstance.patch('/equipments/validate_return', {
      lockerItems: items,
    });
  }

  public validateRetrieval(items: string[]): Promise<AxiosResponse<void>> {
    return this.axiosInstance.patch('/equipments/validate_retrieval', {
      lockerItems: items,
    });
  }

  public validateToCollect(items: string[]): Promise<AxiosResponse<void>> {
    return this.axiosInstance.patch('/equipments/validate_to_collect', {
      lockerItems: items,
    });
  }
}
