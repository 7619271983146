import UseCaseInterface from '@/usecases/UseCaseInterface';
import { StaticFilesRepository } from '@/repositories/StaticFilesRepository';
import type { StaticFile } from '@/types/StaticFile';
import type { StaticFiles } from '@/constants/staticFiles';

class GetStaticFileUseCase extends UseCaseInterface<any, StaticFile> {
  async execute(fileType: StaticFiles): Promise<StaticFile> {
    return StaticFilesRepository.getStaticFiles(fileType);
  }
}

export default new GetStaticFileUseCase();
