import Category from '@/core/api/Category';
import type { CategoryFromBack as CategoryType, ProductByCategory } from '@/types/Category';
import { ProductBuilder } from '@/builder/ProductBuilder';
import LocalStorage from '@/utils/LocalStorage';

export class CategoryRepository {
  public async GetCategories() : Promise<CategoryType[]> {
    const categories = LocalStorage.getCategories();
    if (categories) return JSON.parse(categories);
    const newCategories = await new Category().get().then(({ data }) => data);
    LocalStorage.setCategories(JSON.stringify(newCategories));
    return newCategories;
  }

  public async GetProductsByCategoryId(categoryId: string, filters: object, page_nb: number) : Promise<ProductByCategory> {
    const productsByCategory = await new Category().getById(categoryId, filters, page_nb).then(({ data }) => data);

    const moduleProductBuilder = new ProductBuilder();

    return {
      totalProduct: productsByCategory.totalProduct,
      products: productsByCategory.products.map((p) => moduleProductBuilder.TransformProduct(p)),
    };
  }
}
