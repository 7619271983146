import { LocaleRepository } from '@/repositories';
import LocalStorage from '@/utils/LocalStorage';

class Language {
  async get() {
    const acceptLanguage = LocalStorage.getLanguage();
    if (acceptLanguage) return acceptLanguage;
    const { languages } = (await LocaleRepository.getCountry());
    const language = `${languages.split(',')[0].split('-')[0]}-BE`;
    LocalStorage.setLanguage(language);
    return language;
  }
}

export default new Language();
