import type { FC } from 'react';
import classNames from 'classnames';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@/hooks/useResponsive';
import classes from './styles.module.scss';
import { WPCButton } from '@/components';
import Assets from '@/core/api/Assets';

const IconCookies = Assets.getAssets('/cookies/cookiesIcon.svg');
const CookiesMan = Assets.getAssets('/cookies/cookiesMan.svg');
type CookiesTitleProps = {
  showCookies: () => void;
};

const Title: FC<CookiesTitleProps> = ({ showCookies }) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction={isMobile ? 'column-reverse' : 'row'}
      spacing={1}
    >
      <Grid item md={8}>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12}>
            <img src={IconCookies} alt="IconCookies" />
          </Grid>
          <Grid item xs={12}>
            <div className={classNames(isMobile ? 'vtmn-typo_title-3' : 'vtmn-typo_title-1')}>
              { t('cookies.title') }
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classNames(isMobile ? 'vtmn-typo_text-2' : 'vtmn-typo_title-5')}>
              { t('cookies.description') }
            </div>
          </Grid>
          <Grid item xs={12} className={classes.action_button}>
            <WPCButton onAction={showCookies} variant="primary">
              { t('cookies.actions.gestion') }
            </WPCButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4}>
        <img src={CookiesMan} alt="CookiesMan" className={classes.img} />
      </Grid>
    </Grid>
  );
};

export default Title;
