import type { ReactNode } from 'react';
import {
  createContext,
  useState,
  useMemo,
} from 'react';

export type ErrorManagementContextType = {
  isError: boolean,
  setIsError: (isError: boolean) => void,
};

export const ErrorManagementContext = createContext<ErrorManagementContextType>({
  isError: false,
  setIsError: () => null,
});

export const ErrorManagementProvider = ({ children }: { children: ReactNode }) => {
  const [isError, setIsError] = useState<boolean>(false);

  const values = useMemo(() => ({
    isError,
    setIsError,
  }), [isError, setIsError]);

  return <ErrorManagementContext.Provider value={values}> { children }</ErrorManagementContext.Provider>;
};
