import type { TFunction } from 'i18next';
import type {
  DeclinationFromBack, FunctionalitiesFromBack,
  ModelFromBackNfs, StockItem, UserBenefitsFromBack,
} from '@/types/Model';

export class ModelBuilder {
  public GetModelLight(model: ModelFromBackNfs, stocks: StockItem[]) {
    return {
      modelCode: model.model_code,
      superModelCode: model.super_model_code,
      itemIds: model.items.map((i) => i.item_id),
      label: model.web_label,
      brand: model.brand_label,
      supportUrl: model.support_url,
      reviews: model.reviews,
      designedFor: model.designed_for,
      catchLine: model.catch_line,
      currentPrice: model.standard_price,
      promotionalPrice: model.price !== model.standard_price ? model.price : undefined,
      size: model.items.map((item) => {
        const stock = stocks.find((s) => s.item_id === item.item_id);
        return {
          size: item.size,
          itemId: item.item_id,
          maxQuantity: stock ? stock.quantity : 0,
        };
      }),
    };
  }

  public GetPackshot(packShotFromBack: string[]) {
    return {
      packShot: packShotFromBack
        .map((p, index) => ({ url: p, index })),
    };
  }

  public GetColors(declinationsFromBack: DeclinationFromBack[], modelCode: string) {
    const colors = declinationsFromBack
      .filter((d) => d.url)
      .map((d) => ({
        url: d.url,
        modelCode: d.model_code,
        label: d.color_label,
        index: d.model_code,
      }))
      .sort((colorA) => (colorA.index === modelCode ? -1 : 1));
    return { colors, modelColor: colors.find((c) => c.index === modelCode) };
  }

  public GetAdvantages(userBenefitsFromBack: UserBenefitsFromBack[]) {
    return {
      advantages: userBenefitsFromBack.map((u) => ({
        urlImg: u?.picture_url,
        title: u?.title,
        description: u?.value,
      })),
    };
  }

  public GetFunctionalities(functionalitiesFromBack: FunctionalitiesFromBack[]) {
    if (!functionalitiesFromBack) return { functionalities: [] };
    return {
      functionalities: functionalitiesFromBack.map((f) => ({ title: f.title, description: f.value })) || [],
    };
  }

  public GetComposition(model: ModelFromBackNfs, t: TFunction<'translation', undefined>) {
    const composition = [
      { title: t('product.advices.composition'), description: model.composition },
      { title: t('product.advices.storage'), description: model.specs.technical_advices.storage_advice },
      { title: t('product.advices.restriction'), description: model.specs.technical_advices.user_restriction },
      { title: t('product.advices.approved_by'), description: model.specs.technical_advices.approved_by },
      { title: t('product.advices.maintenance'), description: model.specs.technical_advices.maintenance_advices },
    ];

    return { composition };
  }
}
