class Assets {
  protected getBaseUrl(): string {
    return '/assets';
  }

  public getAssets(path: string): string {
    return `${REACT_APP_WPC_RESOURCES}${this.getBaseUrl()}${path}?ts=${REACT_APP_TS}`;
  }
}

export default new Assets();
