import type { FC } from 'react';
import { VtmnModalDescription } from '@vtmn/react';

type Props = {
  children: JSX.Element | JSX.Element[],
};

const ModalDescription: FC<Props> = ({ children }) => (
  <VtmnModalDescription>
    { children }
  </VtmnModalDescription>
);

export default ModalDescription;
