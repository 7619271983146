import moment from 'moment';
import type { SubscriptionChoice } from '@/pages/SubscriptionSelection/Subscription';

const SUBSCRIPTION = 'wpc.subscription';
const CATEGORY = 'wpc.category';
const ACCEPT_LANGUAGE = 'wpc.accept_language';
const ACCESS_TOKEN_KEY = 'wpc.access_token';
const REFRESH_TOKEN_KEY = 'wpc.refresh_token';
const EXPIRATION_DATE_KEY = 'wpc.expiration_date';
const STATE_KEY = 'login.state';
const I18N = 'i18nextLng';

class LocalStorage {
  static setSelectedSubscription(subscription: SubscriptionChoice) {
    localStorage.setItem(SUBSCRIPTION, JSON.stringify(subscription));
  }

  static getSelectedSubscription(): SubscriptionChoice | null {
    return JSON.parse(localStorage.getItem(SUBSCRIPTION) ?? 'null');
  }

  static clearSubscription() {
    localStorage.removeItem(SUBSCRIPTION);
  }

  static isOnboarding(): boolean {
    return localStorage.getItem(SUBSCRIPTION) !== null;
  }

  static getCategories(): string | null {
    return localStorage.getItem(CATEGORY);
  }

  static clearCategories() {
    localStorage.removeItem(CATEGORY);
  }

  static setCategories(categories: string) {
    localStorage.setItem(CATEGORY, categories);
  }

  static getLanguage(): string | null {
    return localStorage.getItem(ACCEPT_LANGUAGE);
  }

  static clearLanguage() {
    localStorage.removeItem(ACCEPT_LANGUAGE);
  }

  static setLanguage(language: string) {
    localStorage.setItem(ACCEPT_LANGUAGE, language);
  }

  static setToken(token: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  static getToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  static removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  static setState(state: string) {
    localStorage.setItem(STATE_KEY, state);
  }

  static getState(): string | null {
    return localStorage.getItem(STATE_KEY);
  }

  static removeState() {
    localStorage.removeItem(STATE_KEY);
  }

  static setRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  static removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  static setExpirationDate(expirationDate: number) {
    const expiration = moment().add(expirationDate, 'seconds');
    localStorage.setItem(EXPIRATION_DATE_KEY, expiration.unix().toString(10));
  }

  static getExpirationDate(): number | null {
    const expiration : string | null = localStorage.getItem(EXPIRATION_DATE_KEY);
    return expiration ? parseInt(expiration, 10) : null;
  }

  static removeExpirationDate() {
    localStorage.removeItem(EXPIRATION_DATE_KEY);
  }

  static getLng(): string | null {
    return localStorage.getItem(I18N);
  }
}

export default LocalStorage;
