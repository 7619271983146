import { useEffect, useState } from 'react';
import type { FC } from 'react';
import i18n from 'i18next';
import { ContentPage } from '@/components';
import GetStaticFileUseCase from '@/usecases/GetStaticFileUseCase';
import type { StaticFile } from '@/types/StaticFile';
import { StaticFiles } from '@/constants/staticFiles';

const CGL: FC = () => {
  const [cgl, setCgl] = useState<StaticFile>();

  useEffect(() => {
    (async () => {
      const data = await GetStaticFileUseCase.execute(StaticFiles.CGL);
      setCgl(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return cgl ? (
    <ContentPage content={{ title: cgl.title, content: cgl.content }} />
  ) : null;
};

export default CGL;
