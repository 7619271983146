import Api from '../Api';

class Newsletter extends Api {
  protected getBaseUrl(): string {
    return 'newsletter';
  }

  public subscribe(email: string): Promise<void> {
    return this.axiosInstance.post('', {
      email,
    });
  }
}

export default Newsletter;
