import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Spacer, WPCButton, ModalActions, ModalVtmn, ModalDescription,
} from '@/components';
import classes from './styles.module.scss';
import Assets from '@/core/api/Assets';

const ReturnDone = Assets.getAssets('/return/return_done.svg');

type SuccessProps = {
  open: boolean;
  onValid: () => void;
};

const Success: FC<SuccessProps> = ({
  open,
  onValid,
}) => {
  const { t } = useTranslation();

  return (
    <ModalVtmn open={open} onClose={onValid} title={t('return.success.title')}>
      <Spacer vertical={4} />
      <ModalDescription>
        <img src={ReturnDone} alt="Return" className={classes.img} />
        <> {t('return.success.description')} </>
      </ModalDescription>
      <ModalActions>
        <WPCButton
          variant="primary"
          onAction={onValid}
        >
          {t('return.success.ok')}
        </WPCButton>
      </ModalActions>
    </ModalVtmn>
  );
};

export default Success;
