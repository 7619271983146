import type { FC } from 'react';
import parse from 'html-react-parser';
import classes from './styles.module.scss';
import type { FAQContentSection } from '@/types/Page';
import { AccordionQuestionAnswer } from '@/components';

type FAQPageSectionProps = {
  section: FAQContentSection,
  sectionIndex: number
};

const FAQPageSection: FC<FAQPageSectionProps> = ({ section, sectionIndex }) => (
  <>
    <h3 className={classes.title}>{parse(section.title)}</h3>
    <AccordionQuestionAnswer questions={section.questions} keyPrefix={sectionIndex} />
  </>
);

export default FAQPageSection;
