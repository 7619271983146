import type { FC } from 'react';
import { VtmnLoader } from '@vtmn/react';
import type { VtmnLoaderSize } from '@vtmn/react/dist/src/components/indicators/VtmnLoader/types';

type Props = {
  size: VtmnLoaderSize,
  className?: string,
};

const Loader: FC<Props> = ({ size, className }) => (
  <VtmnLoader size={size} className={className} />
);

export default Loader;
