import type { FC, MouseEventHandler } from 'react';
import { VtmnButton } from '@vtmn/react';
import type { VtmnButtonSize } from '@vtmn/react/dist/src/components/actions/VtmnButton/types';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import classNames from 'classnames';
import classes from './styles.module.scss';

type Props = {
  className?: string;
  children: string | JSX.Element;
  iconLeft?: VitamixId;
  iconRight?: VitamixId;
  disabled?: boolean;
  size?: VtmnButtonSize,
  variant?: 'primary-reversed' | 'secondary' | 'ghost' | 'ghost-reversed' | 'tertiary' | 'conversion' | 'primary';
  color?: 'black' | 'blue_SMALL' | 'blue_MEDIUM' | 'blue_BIG' | 'green' | 'grey' | 'transparent';
  onAction: MouseEventHandler<HTMLButtonElement>;
};

const WPCButton: FC<Props> = ({
  className,
  children,
  iconLeft,
  iconRight,
  size = 'medium',
  disabled = false,
  variant = 'primary-reversed',
  color,
  onAction,
}) => (
  <VtmnButton
    className={classNames(className, color ? classes[color] : undefined)}
    onClick={onAction}
    iconLeft={iconLeft}
    iconRight={iconRight}
    size={size}
    variant={variant}
    disabled={disabled}
  >
    {children}
  </VtmnButton>
);

export default WPCButton;
