import UseCaseInterface from './UseCaseInterface';
import { SelectionRepository } from '@/repositories/SelectionRepository';
import type { CreateUserSelection } from '@/types/Locker';

class CreateUserSelectionUseCase extends UseCaseInterface<CreateUserSelection, any> {
  async execute(infos: CreateUserSelection | CreateUserSelection[]) {
    await SelectionRepository.createUserSelection(infos);
  }
}

export default new CreateUserSelectionUseCase();
