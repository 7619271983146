import UseCaseInterface from './UseCaseInterface';
import { ReviewsBuilder } from '@/builder/ReviewsBuilder';
import type { GlobalReviewWithList } from '@/types/Reviews';
import { ModelRepository } from '@/repositories';

type Props = {
  modelCode: string,
  superModelCode: string
};

class GetReviewsForModelUseCase extends UseCaseInterface<Props, GlobalReviewWithList> {
  async execute(props: Props): Promise<GlobalReviewWithList> {
    const modelRepository = new ModelRepository();
    const builder = new ReviewsBuilder();

    const reviewsFromBack = await modelRepository.GetReviewsForModel(props.modelCode, props.superModelCode);
    return builder.GetReviews(reviewsFromBack);
  }
}

export default new GetReviewsForModelUseCase();
