import { useState, useEffect } from 'react';

export const useVisibility = () => {
  const [visibilityStatus, setVisibilityStatus] = useState(false);

  useEffect(() => {
    function handleVisibilityChange() {
      setVisibilityStatus(document.hidden);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return visibilityStatus;
};
