import type { ReactNode } from 'react';
import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { SubscriptionStatus } from '@/enum/subscriptionStatus';
import GetUserRightsUseCase from '@/usecases/GetUserRightsUseCase';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { AuthState } from '@/context/Auth/AuthState';
import { ROUTES } from '@/pages/routes';
import { RightsState } from '@/context/Rights/RightsState';

export type RightsContextType = {
  isActive: RightsState,
  isDefaultPayment: boolean,
  isUnknown: boolean,
  isCanceled: boolean,
  updateRights: () => void,
};

export const RightsContext = createContext<RightsContextType>({
  isActive: RightsState.LOADING,
  isDefaultPayment: false,
  isCanceled: false,
  isUnknown: false,
  updateRights: () => {},
});

export const RightsProvider = ({ children }: { children: ReactNode }) => {
  const [isActive, setIsActive] = useState<RightsState>(RightsState.LOADING);
  const [isUnknown, setIsUnknown] = useState<boolean>(false);
  const [isDefaultPayment, setIsDefaultPayment] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const { isAuth } = useContext(AuthContext);
  const navigateTo = useNavigate();
  const updateRights = useCallback(async () => {
    setIsDefaultPayment(false);
    setIsCanceled(false);
    setIsUnknown(false);
    if (isAuth === AuthState.DISCONNECTED) {
      setIsActive(RightsState.UNKNOWN);
      return;
    }
    if (isAuth === AuthState.CONNECTED) {
      setIsDefaultPayment(false);
      try {
        const userRights = await GetUserRightsUseCase.execute();
        switch (userRights) {
          case SubscriptionStatus.WARNING:
            setIsDefaultPayment(true);
            setIsActive(RightsState.ACTIVE);
            break;
          case SubscriptionStatus.ACTIVE:
            setIsActive(RightsState.ACTIVE);
            break;
          case SubscriptionStatus.CANCELED:
            setIsActive(RightsState.ACTIVE);
            setIsCanceled(true);
            break;
          default:
            setIsActive(RightsState.INACTIVE);
            break;
        }
      } catch {
        setIsActive(RightsState.UNKNOWN);
        setIsUnknown(true);
      }
    }
  }, [isAuth]);

  useEffect(() => {
    (async () => {
      await updateRights();
    })();
  }, [isAuth, updateRights]);

  useEffect(() => {
    if (isCanceled) navigateTo(ROUTES.CANCELLATION);
  }, [isCanceled, navigateTo]);

  const value = useMemo(() => ({
    isActive,
    isUnknown,
    isDefaultPayment,
    updateRights,
    isCanceled,
  }), [isActive, isDefaultPayment, updateRights, isCanceled, isUnknown]);

  return <RightsContext.Provider value={value}>{children}</RightsContext.Provider>;
};
