import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { FC } from 'react';
import classes from './styles.module.scss';
import Assets from '@/core/api/Assets';

const visuals = Assets.getAssets('/landing/Visuals.png');

const CatchLineCloture: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <span className="vtmn-typo_title-5 vtmn-text-content-secondary">
          { t('landing.inactive_user_landing.catch_line.title') }
        </span>
        <span className={classNames('vtmn-typo_title-2', classes.explanation)}>
          {t('landing.inactive_user_landing.catch_line.explanation')}
        </span>
        <span>{t('landing.inactive_user_landing.catch_line.subtitle_after_6_may')}</span>
      </div>
      <img src={visuals} width={450} alt="" loading="lazy" />
    </div>
  );
};

export default CatchLineCloture;
