import type { FC } from 'react';
import { VtmnModal, VtmnModalTitle } from '@vtmn/react';

type Props = {
  children: JSX.Element | JSX.Element[],
  title: string,
  onClose: () => void,
  open: boolean,
  className?: string,
  classNameTitle?: string,
};

const Modal: FC<Props> = ({
  children,
  title,
  open,
  onClose,
  className,
  classNameTitle,
}) => (
  <VtmnModal open={open} onClose={onClose} className={className}>
    <VtmnModalTitle className={classNameTitle}>
      { title }
    </VtmnModalTitle>
    { children }
  </VtmnModal>
);

export default Modal;
