import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import InfoToggle from '../InfoToggle';
import classes from './styles.module.scss';
import Assets from '@/core/api/Assets';

const Thermometer = Assets.getAssets('/icon/thermometer.svg');
const Gear = Assets.getAssets('/icon/gear.svg');

const Desktop: FC = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid xs={6} container className="vtmn-typo_title-5">
        <Grid xs={1} lg={0.7}>
          <img src={Gear} alt="Gear" />
        </Grid>
        <Grid xs={11} lg={11.3}>
          { t('cookies.what_is.necessary.title') }
        </Grid>
      </Grid>
      <Grid xs={6} container className="vtmn-typo_title-5">
        <Grid sm={1} lg={0.7}>
          <img src={Thermometer} alt="Gear" />
        </Grid>
        <Grid xs={11} lg={11.3}>
          { t('cookies.what_is.not_necessary.title') }
        </Grid>
      </Grid>
      <Grid container xs={6}>
        <Grid xs={11} lg={11.3} xsOffset={1} lgOffset={0.7}>
          { t('cookies.what_is.necessary.description') }
        </Grid>
      </Grid>
      <Grid container xs={6}>
        <Grid xs={11} lg={11.3} xsOffset={1} lgOffset={0.7}>
          { t('cookies.what_is.not_necessary.description') }
        </Grid>
      </Grid>
      <Grid container xs={6}>
        <Grid xs={11} lg={11.3} xsOffset={1} lgOffset={0.7}>
          <InfoToggle
            disabled
            traduction="necessary"
            className="info_disabled"
          />
        </Grid>
      </Grid>
      <Grid container xs={6}>
        <Grid xs={11} lg={11.3} xsOffset={1} lgOffset={0.7}>
          <InfoToggle
            disabled={false}
            traduction="not_necessary"
            className="info"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Desktop;
