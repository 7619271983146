import { createContext, useMemo, useState } from 'react';

export type LanguageContextType = {
  updateLanguage: boolean,
  setUpdateLanguage: (updateLanguage: boolean) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  updateLanguage: false,
  setUpdateLanguage: () => null,
});

export const LanguageProvider = ({ children }: { children: JSX.Element }) => {
  const [updateLanguage, setUpdateLanguage] = useState<boolean>(false);

  const value = useMemo(() => ({
    updateLanguage,
    setUpdateLanguage,
  }), [updateLanguage, setUpdateLanguage]);
  return <LanguageContext.Provider value={value}>{ children }</LanguageContext.Provider>;
};
