import type { FC } from 'react';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { useResponsive } from '@/hooks/useResponsive';
import classes from './styles.module.scss';
// import { SubscriptionInfos } from './SubscriptionInfos';
import Selection from './Selection';
// import GetUserGaugeUseCase from '@/usecases/GetUserGaugeUseCase';
import History from './History';
import { Equipment } from './Equipment';
import {
  Modal, PopUpDefaultPayment, Spacer, Loader, Chip,
} from '@/components';
import ValidateSelectionUseCase from '@/usecases/ValidateSelectionUseCase';
import GetUserSelectionUseCase from '@/usecases/GetUserSelectionUseCase';
import type { LockerItem } from '@/types/Locker';
import { MyLocker } from '@/pages/Locker/MyLocker';
import { LockerContext } from '@/context/Locker/LockerProvider';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';
import ErrorValidate from './ErrorValidate';
import DeleteItemUserSelectionUseCase from '@/usecases/DeleteItemUserSelectionUseCase';
import { LanguageContext } from '@/context/Language/LanguageProvider';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { AuthState } from '@/context/Auth/AuthState';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { MEDALLIA_QUERY_PARAMS_NAME } from '@/constants/medallia';
import { ROUTES } from '@/pages/routes';
import { Medallia } from '@/enum/Medallia';
import ValidateDeliveryTimeButton from './ValidateDeliveryTimeButton';
import Assets from '@/core/api/Assets';
import { RightsState } from '@/context/Rights/RightsState';
import { useTracking } from '@/hooks/useTracking';
import LocalStorage from '@/utils/LocalStorage';
import BearerToken from '@/core/BearerToken';

const validation = Assets.getAssets('/locker/validation.svg');

const tabs = [
  { name: 'locker.tabs.selection', value: 'SELECTION', disabled: true },
  { name: 'locker.tabs.equipement', value: 'EQUIPEMENT', disabled: false },
  { name: 'locker.tabs.historique', value: 'HISTORIQUE', disabled: false },
];

const ANAIS = 'cc40dcdc-8b3a-4106-b1b3-b34f63bd1000';
const ANAIS2 = '283c12e3-3cf0-47fd-a640-92f3e6d819b1';
const SJTIN = '8f74aa30-6be9-4a86-b4bf-c3222faacd03';

const Locker: FC = () => {
  const { isDefaultPayment, isActive } = useContext(RightsContext);
  const { updateNbItemsInLocker } = useContext(LockerContext);
  const { setIsError } = useContext(ErrorManagementContext);
  const { isAuth } = useContext(AuthContext);

  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const { backEndEvent } = useTracking();
  const navigateTo = useNavigate();

  const [searchParams] = useSearchParams();

  const [tabSelect, setTabSelect] = useState<string>('EQUIPEMENT');
  const [validationModal, setValidationModal] = useState(false);
  /* const [gauge, setGauge] = useState({
    garageMaxSize: 0,
    garageCurrentSize: 0,
    subscriptionType: '',
  }); */
  const [items, setItems] = useState<LockerItem[]>([]);
  const [itemsError, setItemsError] = useState<LockerItem[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderValidateSelection, setLoaderValidateSelection] = useState<boolean>(false);
  const [openPopUpDefaultPayment, setOpenPopUpDefaultPayment] = useState<boolean>(false);
  const [openPopUpConfirm, setOpenPopUpConfirm] = useState(false);

  useEffect(() => {
    if (searchParams.has(MEDALLIA_QUERY_PARAMS_NAME)) setTabSelect('EQUIPEMENT');
  }, [searchParams]);

  const closeValidationModal = useCallback(() => {
    setValidationModal(false);
    navigateTo({ pathname: ROUTES.LOCKER, search: `?${MEDALLIA_QUERY_PARAMS_NAME}=${Medallia.validation}` });
  }, [navigateTo]);

  const { updateLanguage, setUpdateLanguage } = useContext(LanguageContext);

  /* const updateGauge = useCallback(async () => {
    try {
      const gaugeValue = await GetUserGaugeUseCase.execute();
      setGauge(gaugeValue);
    } catch {
      setIsError(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps */

  /* useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      const fetchGauge = async () => {
        const gaugeValue = await GetUserGaugeUseCase.execute();
        setGauge(gaugeValue);
      };
      fetchGauge()
        .catch(() => { setIsError(true); });
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (isAuth === AuthState.LOADING) {
      return;
    }
    if ((isAuth === AuthState.CONNECTED && isActive === RightsState.INACTIVE)) {
      navigateTo(ROUTES.SUBSCRIBE);
    }

    if (isAuth === AuthState.DISCONNECTED) {
      navigateTo(ROUTES.LANDING);
    }
  }, [isActive, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserSelection = useCallback(async () => {
    try {
      setLoader(true);
      setItems(await GetUserSelectionUseCase.execute());
    } catch {
      setIsError(true);
    } finally {
      setLoader(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      (async () => {
        await getUserSelection();
      })();
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const displayButton = () => {
    const token = LocalStorage.getToken();
    if (!token) return false;
    const decodeToken = BearerToken.decode(token);
    if (!decodeToken || !decodeToken.sub) return false;
    return [ANAIS, SJTIN, ANAIS2].includes(decodeToken.sub);
  };

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      (async () => {
        if (!updateLanguage) return;
        await getUserSelection();
        setUpdateLanguage(false);
      })();
    }
  }, [updateLanguage, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateSelection = useCallback(async () => {
    setOpenPopUpConfirm(false);
    try {
      if (isDefaultPayment) {
        setOpenPopUpDefaultPayment(true);
        return;
      }
      setLoaderValidateSelection(true);
      setItemsError([]);
      await ValidateSelectionUseCase.execute();
      setItems(await GetUserSelectionUseCase.execute());
      setValidationModal(true);
      updateNbItemsInLocker();
    } catch (e: any) {
      if (e.response.status === 404) {
        backEndEvent(MATOMO_CATEGORY.LOCKER.PRODUCT_UNAVAILABLE);
        const itemIds = e.response.data.object;
        const itemsInLockerError = items.filter((i) => itemIds.includes(i.itemId));
        setItemsError(itemsInLockerError);
      } else setIsError(true);
    } finally {
      setLoaderValidateSelection(false);
    }
  }, [isDefaultPayment, updateNbItemsInLocker, setIsError, backEndEvent, items]);

  const continueWithoutUnavailable = useCallback(() => {
    (async () => {
      try {
        setLoaderValidateSelection(true);
        // eslint-disable-next-line no-restricted-syntax
        for (const item of itemsError) {
          // eslint-disable-next-line no-await-in-loop
          await DeleteItemUserSelectionUseCase.execute({ uuid: item.uuid });
        }
        setItemsError([]);
        const newItems = await GetUserSelectionUseCase.execute();
        setItems(newItems);
        if (newItems.length === 0) return;
        await validateSelection();
      } catch {
        setIsError(true);
      } finally {
        setLoaderValidateSelection(false);
        // await updateGauge();
        updateNbItemsInLocker();
      }
    })();
  }, [itemsError, setIsError, updateNbItemsInLocker, validateSelection]);

  return (
    <div className={classNames(classes.root, { [classes.background_loader]: loaderValidateSelection })}>
      {loaderValidateSelection && <Loader className={classes.loader} size="large" />}
      {!isMobile && (
        <div>
          <div className={classNames('vtmn-typo_title-2 vtmn-font-bold', classes.title)}>
            {t('locker.title')}
          </div>
          <Spacer vertical={2} horizontal={2} />
        </div>
      )}
      { displayButton()
        && (
          <div className={classes.button_validation} onClick={() => setOpenPopUpConfirm(true)}>
            Bravo on display
          </div>
        )}
      <div className={classNames('vvtmn-typo_text-2 vtmn-text-content-secondary', classes.delivery_time)}>
        {t('locker.warning_order_after_6_may')}
      </div>
      <Spacer vertical={isMobile ? 2 : 3.5} horizontal={2} />
      <div className={classes.container}>
        <div>
          <div className={classes.background}>
            <div className={classes.tabs}>
              {tabs.map((tab, index) => (
                <Chip
                  disabled={tab.disabled}
                  key={index}
                  onClick={() => setTabSelect(tab.value)}
                  selected={tab.value === tabSelect}
                  text={t(tab.name)}
                />
              ))}
            </div>
            <div className={classNames(classes.info_tab)}>
              {tabSelect === 'SELECTION' && (
                <Selection
                  loader={loader}
                  items={items}
                  setLoader={setLoader}
                  updateGauge={() => {}}
                  setItems={setItems}
                />
              )}
              {tabSelect === 'HISTORIQUE' && <History />}
              {tabSelect === 'EQUIPEMENT' && <Equipment />}
            </div>
          </div>
          {tabSelect === 'EQUIPEMENT' && (
            <div>
              <MyLocker />
            </div>
          )}
        </div>

        {/* <div className={classNames({ [classes.background_infos_user]: !isMobile, [classes.subscription]: !isMobile })}>
          <SubscriptionInfos gauge={gauge} />

          {!isMobile && !!items.length && tabSelect === 'SELECTION' && (
            <ValidateDeliveryTimeButton
              validateSelection={() => setOpenPopUpConfirm(true)}
            />
          )}
        </div> */}
      </div>
      {validationModal && (
        <Modal
          title={t('locker.selection.validate.title')}
          onClose={closeValidationModal}
          description={t('locker.selection.validate.description')}
          titleSecondButton={t('locker.selection.validate.close')}
          onClickSecondButton={closeValidationModal}
          img={<img style={{ margin: 'auto' }} src={validation} alt="" width={150} />}
        />
      )}
      {openPopUpConfirm && (
        <Modal
          title={t('locker.selection.confirmation.title')}
          onClose={() => setOpenPopUpConfirm(false)}
          description={t('locker.selection.confirmation.description')}
          titleSecondButton={t('locker.selection.confirmation.confirm')}
          onClickSecondButton={validateSelection}
          onClickFirstButton={() => setOpenPopUpConfirm(false)}
          titleFirstButton={t('locker.selection.confirmation.cancel')}
        />
      )}
      <ErrorValidate
        lockerItemsUnavailable={itemsError}
        closeDrawer={() => setItemsError([])}
        continueWithoutUnavailable={continueWithoutUnavailable}
      />
      <PopUpDefaultPayment
        openPopUp={openPopUpDefaultPayment}
        closePopUp={() => setOpenPopUpDefaultPayment(false)}
      />
      {isMobile && !!items.length && tabSelect === 'SELECTION' && (
        <ValidateDeliveryTimeButton
          className={classes.floating_button}
          validateSelection={() => setOpenPopUpConfirm(true)}
        />
      )}
    </div>
  );
};

export default Locker;
