import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import ChildTable from './ChildTable';
import type { Cookie } from '@/types/Config';

type Props = {
  cookie: Cookie;
};

const Body: FC<Props> = ({ cookie }) => (
  <tr>
    <td className={classes.cell}>
      <table className={classes.table}>
        <tbody>
          { cookie.tag_solution.map((tag, indexTag) => (
            <ChildTable value={tag} key={indexTag} className="vtmn-font-bold" />
          ))}
        </tbody>
      </table>
    </td>
    <td className={classNames(classes.cell, classes.cell_without_child)}>
      { cookie.objectif }
    </td>
    <td className={classes.cell}>
      <table className={classes.table}>
        <tbody>
          { cookie.names.map((name, indexName) => (
            <ChildTable value={name} key={indexName} />
          ))}
        </tbody>
      </table>
    </td>
    <td className={classes.cell}>
      <table className={classes.table}>
        <tbody>
          { cookie.lifetime.map((lifetime, indexLifetime) => (
            <ChildTable value={lifetime} key={indexLifetime} />
          ))}
        </tbody>
      </table>
    </td>
  </tr>
);

export default Body;
