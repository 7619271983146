import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import { MenuItems } from '@/enum/Menu';
import Assets from '@/core/api/Assets';

const LogoutIcon = Assets.getAssets('/icon/logout.svg');

export const menuItems = [
  {
    firstIcon: 'checkbox-multiple-line' as VitamixId,
    text: 'navigation.account_part.packages',
    enum: MenuItems.PACKAGE,
  },
  {
    firstIcon: 'question-line' as VitamixId,
    text: 'navigation.account_part.faq',
    enum: MenuItems.FAQ,
  },
  {
    firstIcon: 'user-line' as VitamixId,
    text: 'navigation.account_part.decathlon_account',
    enum: MenuItems.DECATHLON_ACCOUNT,
    secondIcon: 'external-link-line' as VitamixId,
  },
  {
    firstImg: LogoutIcon,
    text: 'navigation.account_part.logout',
    enum: MenuItems.LOGOUT,
    isLast: true,
  },
];
