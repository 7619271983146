import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Model as ModelType } from '@/types/Model';
import SectionList from './SectionList';
import type { ElementList, PageInfo } from '@/types/Component';
import classes from './styles.module.scss';
import DrawerCardInfo from './DrawerCardInfo';
import DrawerReviews from './DrawerReviews';
import { useResponsive } from '@/hooks/useResponsive';
import Assets from '@/core/api/Assets';

const BubbleIcon = Assets.getAssets('/icon/bubble.svg');
const ToolsIcon = Assets.getAssets('/icon/tools.svg');

type ProductCardInfoProps = {
  model: ModelType,
  setPropertyGenericInfo: (property: string) => void
};

const ProductCardInfo: FC<ProductCardInfoProps> = ({ model, setPropertyGenericInfo }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const [openDrawerGeneric, setOpenDrawerGeneric] = useState<boolean>(false);
  const [openDrawerReviews, setOpenDrawerReviews] = useState<boolean>(false);
  const [pageInformation, setPageInformation] = useState<PageInfo>();

  const getElementsValue = (action: string) => {
    switch (action) {
      case 'advantages':
        return model.advantages;
      case 'functionalities':
        return model.functionalities;
      default:
        return model.composition;
    }
  };

  function updateElementDrawer(action: string) {
    if (action === 'support') {
      window.open(model.supportUrl);
    } else if (!isMobile) {
      if (action === 'reviews') {
        setOpenDrawerReviews(true);
      } else {
        setPageInformation({
          title: t(`product.${action}`),
          elements: getElementsValue(action),
        });
        setOpenDrawerGeneric(true);
      }
    } else setPropertyGenericInfo(action);
  }

  const elements: ElementList[] = [
    {
      iconStart: 'award-line',
      iconEnd: 'chevron-right-fill',
      text: 'product.product_card_info.advantage',
      action: 'advantages',
    },
    {
      iconStart: 'information-line',
      iconEnd: 'chevron-right-fill',
      text: 'product.product_card_info.technical_info',
      action: 'functionalities',
    },
    {
      imgStart: BubbleIcon,
      iconEnd: 'chevron-right-fill',
      text: 'product.product_card_info.compo',
      action: 'compo',
    },
    {
      iconStart: 'star-line',
      iconEnd: 'chevron-right-fill',
      text: 'product.product_card_info.reviews',
      action: 'reviews',
    },
    {
      imgStart: ToolsIcon,
      iconEnd: 'external-link-line',
      text: 'product.product_card_info.support',
      action: 'support',
    },
  ];

  const getElements = useCallback(() => {
    if (!model.supportUrl) return elements.filter((e) => e.action !== 'support');
    return elements;
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <SectionList elements={getElements()} onClick={(action) => { updateElementDrawer(action); }} />
      <DrawerCardInfo
        drawerInfo={pageInformation}
        isOpen={openDrawerGeneric}
        updateSwipeableDrawer={setOpenDrawerGeneric}
      />
      <DrawerReviews
        model={model}
        isOpen={openDrawerReviews}
        updateSwipeableDrawer={setOpenDrawerReviews}
      />
    </div>
  );
};

export default ProductCardInfo;
