export enum StaticFiles {
  PROMO_CODE = 'promo_code',
  CGU = 'cgu',
  CGL = 'cgl',
  CGA = 'cga',
  mentions = 'mentions',
  faq = 'faq',
  PERSONAL_DATA = 'personal_data',
  CURRENT_DATA = 'current_data',
}
