import type { FC } from 'react';
import { useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '@/hooks/useTracking';
import PopUp from '../vtmn/PopUp';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';
import classes from './styles.module.scss';
import { WPCButton, ModalActions } from '@/components';
import { ROUTES } from '@/pages/routes';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import Assets from '@/core/api/Assets';

const ErrorImg = Assets.getAssets('/error/error.svg');
const PopUpError: FC = () => {
  const { t } = useTranslation();
  const { isError, setIsError } = useContext(ErrorManagementContext);
  const navigateTo = useNavigate();
  const { clickEvent } = useTracking();

  const goToHome = useCallback(() => {
    setIsError(false);
    navigateTo(ROUTES.HOME_PAGE);
  }, [setIsError, navigateTo]);

  useEffect(() => {
    if (!isError) return;
    clickEvent(MATOMO_CATEGORY.ERROR_500);
  }, [isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PopUp
      isOpen={isError}
      onClose={() => setIsError(false)}
      title={t('server_error.title')}
    >
      <div>
        <div className={classes.root}>
          <img src={ErrorImg} alt="error" className={classes.img} />
          <div className="vtmn-typo_text-2"> {t('server_error.desc') } </div>
        </div>
        <ModalActions>
          <WPCButton
            variant="primary"
            onAction={goToHome}
          >
            {t('actions.home')}
          </WPCButton>
          <WPCButton
            className={classes.button}
            variant="secondary"
            onAction={() => { setIsError(false); }}
          >
            {t('actions.back')}
          </WPCButton>
        </ModalActions>
      </div>
    </PopUp>
  );
};

export default PopUpError;
