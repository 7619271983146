import UseCaseInterface from '@/usecases/UseCaseInterface';
import { ConfigRepository } from '@/repositories';
import SessionStorage from '@/utils/SessionStorage';
import type { ConfigCategories, Category } from '@/types/Config';

class GetDictionaryCategoryUseCase extends UseCaseInterface<void, Category[] | undefined> {
  async execute(): Promise<Category[] | undefined> {
    let dictionary: ConfigCategories[];
    const saved = SessionStorage.getCategories();
    if (!saved) {
      dictionary = await ConfigRepository.getCategories();
      SessionStorage.setCategories(JSON.stringify(dictionary));
    } else {
      dictionary = JSON.parse(saved);
    }
    const categoryByCountry = dictionary.find((d : any) => d.country_code === 'BE');
    return categoryByCountry ? categoryByCountry.categories : undefined;
  }
}

export default new GetDictionaryCategoryUseCase();
