import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { AuthContext } from '@/context/Auth/AuthProvider';
import GetUserSelectionNbItemsUseCase from '@/usecases/GetUserSelectionNbItemsUseCase';
import { AuthState } from '@/context/Auth/AuthState';

export type LockerContextType = {
  nbItemsInLocker: number,
  updateNbItemsInLocker: () => void;
};

export const LockerContext = createContext<LockerContextType>({
  nbItemsInLocker: 0,
  updateNbItemsInLocker: () => null,
});

export const LockerProvider = ({ children } : { children: JSX.Element }) => {
  const { isAuth } = useContext(AuthContext);

  const [nbItemsInLocker, setNbItemsInLocker] = useState<number>(0);

  const updateNbItemsInLocker = useCallback(async () => {
    if (isAuth !== AuthState.CONNECTED) return;
    setNbItemsInLocker(await GetUserSelectionNbItemsUseCase.execute());
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      await updateNbItemsInLocker();
    })();
  }, [updateNbItemsInLocker, isAuth]);

  const values = useMemo(() => ({
    nbItemsInLocker,
    updateNbItemsInLocker,
  }), [nbItemsInLocker, updateNbItemsInLocker]);

  return <LockerContext.Provider value={values}>{children}</LockerContext.Provider>;
};
