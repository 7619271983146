import UseCaseInterface from '@/usecases/UseCaseInterface';
import type { Testimony } from '@/types/Config';
import { ConfigRepository } from '@/repositories';
import SessionStorage from '@/utils/SessionStorage';

class GetTestimonalsUseCase extends UseCaseInterface<void, Testimony> {
  async execute(): Promise<Testimony> {
    const saved = SessionStorage.getTestimonials();
    if (!saved) {
      const testimonials = await ConfigRepository.getTestimonials();
      SessionStorage.setTestimonials(JSON.stringify(testimonials));
      return testimonials;
    }
    return JSON.parse(saved);
  }
}

export default new GetTestimonalsUseCase();
