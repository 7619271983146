import type { FC } from 'react';
import { memo } from 'react';

type SpacerProps = {
  vertical?: number;
  horizontal?: number;
};

const Spacer: FC<SpacerProps> = ({ vertical, horizontal }) => (
  <div
    style={{
      height: `${(vertical || 0) * 8}px`,
      width: `${(horizontal || 0) * 8}px`,
    }}
  />
);

export default memo(Spacer);
