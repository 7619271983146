import type { FC } from 'react';
import parse from 'html-react-parser';

import type { PageContentQuestion } from '@/types/Page';
import Accordion from './Accordion';

type AccordionQuestionAnswerProps = {
  questions: PageContentQuestion[],
  keyPrefix?: string | number
};

const AccordionQuestionAnswer: FC<AccordionQuestionAnswerProps> = ({ questions, keyPrefix }) => (
  <>
    {questions.map((question, questionIndex) => (
      <Accordion
        key={`${keyPrefix || ''}_${questionIndex}`}
        isFirst={questionIndex === 0}
        isLast={questionIndex === (questions || []).length - 1}
        summary={parse(question.question)}
      >
        {parse(question.answer)}
      </Accordion>
    ))}
  </>
);

export default AccordionQuestionAnswer;
