import UseCaseInterface from './UseCaseInterface';
import { UserRepository } from '@/repositories';

class GetUserNameUseCase extends UseCaseInterface<any, string | null> {
  async execute() : Promise<string | null> {
    return UserRepository.getUserName();
  }
}

export default new GetUserNameUseCase();
