import { LockerEquipment } from '@/core/api';
import type { LockerItemFromBack, ReturnItem } from '@/types/Locker';
import { LOCKER_STATE } from '@/constants/locker';

export class LockerRepository {
  static async getEquipment(): Promise<LockerItemFromBack[] | []> {
    return new LockerEquipment().getEquipment().then(({ data }) => data);
  }

  static async getMyLocker(): Promise<LockerItemFromBack[]> {
    return new LockerEquipment().getMyLocker().then(({ data }) => data);
  }

  static returnEquipments(items: ReturnItem[]): Promise<any> {
    return new LockerEquipment().returnEquipments(items);
  }

  static async validateEquipment(items: string[], status: string): Promise<void> {
    switch (status) {
      case LOCKER_STATE.VALIDATED:
        await new LockerEquipment().validateToCollect(items);
        break;
      case LOCKER_STATE.TO_COLLECT:
        await new LockerEquipment().validateRetrieval(items);
        break;
      case LOCKER_STATE.TO_RETURN:
        await new LockerEquipment().validateReturn(items);
        break;
      default:
        throw new Error();
    }
  }
}
