import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import classes from './styles.module.scss';
import LocalStorage from '@/utils/LocalStorage';
import { LanguageContext } from '@/context/Language/LanguageProvider';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { useTracking } from '@/hooks/useTracking';
import { Icon } from '@/components';

const LanguageSelector: FC = () => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const { language } = i18n;
  const languages: string[] = (i18n.options.supportedLngs || []).filter((l: string) => l !== 'cimode');
  const { setUpdateLanguage } = useContext(LanguageContext);

  const { clickEvent } = useTracking();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const handleChange = async (event: SelectChangeEvent) => {
    const newLanguage = event.target.value as string;
    const country = LocalStorage.getLanguage()?.split('-')[1];
    LocalStorage.setLanguage(`${newLanguage}-${country}`);
    await i18n.changeLanguage(newLanguage);
    setUpdateLanguage(true);
    clickEvent(MATOMO_CATEGORY.FOOTER.LANGUAGE);
  };

  useEffect(() => {
    (async () => {
      const languageInSession = LocalStorage.getLanguage();
      if (languageInSession) {
        await i18n.changeLanguage(languageInSession.split('-')[0]);
      }
    })();
  }, [i18n]);

  return (
    <div className={classes.root}>
      <Select
        IconComponent={() => null}
        style={{
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={language}
        onChange={handleChange}
      >
        {languages.map((l: string) => (
          <MenuItem value={l} key={l}>
            {l.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
      <Icon value="chevron-down-fill" variant="default" onClick={handleOpenClose} />
    </div>
  );
};

export default LanguageSelector;
