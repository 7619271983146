import type { FC } from 'react';
import classes from './styles.module.scss';

type ChildType = JSX.Element | JSX.Element[] | string | null;

type PageContainerProps = {
  className?: string,
  children: ChildType | ChildType[]
};

const Page:FC<PageContainerProps> = ({ children, className }) => (
  <div className={classes.root}>
    <div className={classes.content}>
      <div className={className}>{children}</div>
    </div>
  </div>
);

export default Page;
