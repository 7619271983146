import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Category as CategoryType } from '@/types/Category';
import classes from './styles.module.scss';
import { ROUTES } from '@/pages/routes';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';
import type { Category } from '@/types/Config';
import Assets from '@/core/api/Assets';
import { Icon } from '@/components';

const NoImg = Assets.getAssets('/general/noImg.svg');

type CategoriesProps = {
  parentId: string | null,
  categories: CategoryType[];
  displayImg?: boolean,
  lastPartNavBar?: boolean,
  selectCategory: (category: CategoryType, isChild: boolean) => void;
  type: string | null,
  dictionaryCategories: Category[] | undefined,
};

const Categories: FC<CategoriesProps> = ({
  parentId, categories, displayImg = false, lastPartNavBar = false, selectCategory, type, dictionaryCategories,
}) => {
  const navigateTo = useNavigate();
  const getCategoriesChildren = useCallback(() => {
    if (!categories) return [];
    const childCategories = categories.filter((c) => c.parentId === parentId);
    if (type !== 'MAIN' || !dictionaryCategories) return childCategories.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    return childCategories.filter((c) => dictionaryCategories.map((dictionaryCat) => dictionaryCat.id).includes(c.categoryId));
  }, [dictionaryCategories, categories, parentId, type]);

  function getChildren(parentId: string) {
    if (lastPartNavBar || !categories) return false;
    return (categories.filter((c) => c.parentId === parentId)).length > 0;
  }

  function clickOnCategory(category: CategoryType) {
    if (!getChildren(category.categoryId)) {
      GoogleAnalytics.hit(GaEvents.CLICK, {
        label: GA_LABELS.CATALOG.VIEW_ITEM_LIST,
        item_category: category.categoryId,
        category_name: category.label,
      });
      navigateTo(`${ROUTES.PRODUCT_LIST.replace(':categoryId', category.categoryId)}`);
    } else selectCategory(category, getChildren(category.categoryId));
  }

  const getImg = (categoryId: string) => {
    if (!dictionaryCategories) return NoImg;
    const categoryFind = dictionaryCategories.find((dictionaryCategory) => dictionaryCategory.id === categoryId);
    return categoryFind ? categoryFind.img : NoImg;
  };

  return (
    <div className={classes.root}>
      { getCategoriesChildren().map((c) => (
        <button className={classes.category_button} key={c.categoryId} onClick={() => clickOnCategory(c)}>
          <div className={classes.category}>
            <div className={classes.label_img}>
              { displayImg
                && <img alt="imgCat" src={getImg(c.categoryId)} className={classes.img} />}
              <div className={classes.label}>{ c.label }</div>
            </div>
            <div className={classes.icon}>
              { getChildren(c.categoryId)
                && <Icon value="chevron-right-fill" variant="default" className={classes.icon} size={16} />}
            </div>
          </div>
          <hr />
        </button>
      ))}
    </div>
  );
};

export default Categories;
