import type { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  createContext, useCallback, useMemo, useState,
} from 'react';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';

type Prices = {
  minimum: number | null,
  maximum: number | null,
};

export type PricesContextType = {
  prices: Prices,
  setMinimumPrice: (price: number) => void,
  setMaximumPrice: (price: number) => void,
  setPrices: Dispatch<SetStateAction<Prices>>,
  isPricesOpen: boolean,
  setIsPricesOpen: Dispatch<SetStateAction<boolean>>,
  handleOpen: () => void,
};

export const PricesContext = createContext<PricesContextType>({
  prices: {
    minimum: null,
    maximum: null,
  },
  setMinimumPrice: () => null,
  setMaximumPrice: () => null,
  setPrices: () => null,
  isPricesOpen: false,
  setIsPricesOpen: () => null,
  handleOpen: () => null,
});

export const PricesProvider = ({ children }: { children: ReactNode }) => {
  const [prices, setPrices] = useState<Prices>({
    minimum: null,
    maximum: null,
  });
  const [isPricesOpen, setIsPricesOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!isPricesOpen) {
      GoogleAnalytics.hit(GaEvents.CLICK, {
        label: GA_LABELS.CATALOG.USE_FILTER,
        filter_terms: 'PRICE',
      });
    }
    setIsPricesOpen((isOpen) => !isOpen);
  }, [isPricesOpen]);

  const setMinimumPrice = useCallback((price: number) => {
    setPrices((current) => ({ ...current, minimum: price }));
  }, []);

  const setMaximumPrice = useCallback((price: number) => {
    setPrices((current) => ({ ...current, maximum: price }));
  }, []);

  const value = useMemo(() => ({
    prices,
    setMaximumPrice,
    setMinimumPrice,
    setPrices,
    isPricesOpen,
    setIsPricesOpen,
    handleOpen,
  }), [prices, handleOpen, isPricesOpen, setMinimumPrice, setMaximumPrice]);
  return (
    <PricesContext.Provider value={value}>
      {children}
    </PricesContext.Provider>
  );
};
