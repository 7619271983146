import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BackButton, Spacer, TextInput } from '@/components';
import styles from './styles.module.scss';
import CancellationUseCase from '@/usecases/CancellationUseCase';
import type { CancellationForm } from '@/types/User';
import { CancellationValue } from '@/enum/Cancellation';
import { ROUTES } from '@/pages/routes';
import { RightsContext } from '@/context/Rights/RightsProvider';
import Question from '@/pages/Cancellation/Questions/Question';

const Cancellation = () => {
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const [answer, setAnswer] = useState<CancellationForm>({
    response: null,
  });
  const { isCanceled, updateRights } = useContext(RightsContext);
  const backButton = () => {
    navigateTo(ROUTES.LANDING);
  };

  useEffect(() => {
    if (!isCanceled) navigateTo(ROUTES.LANDING);
  }, [isCanceled, navigateTo]);

  const submit = async () => {
    await CancellationUseCase.execute({
      response: answer.response,
      detail: answer.response === CancellationValue.NOT_SATISFIED ? answer.notSatisfied : answer.detail,
    });
    updateRights();
    navigateTo(ROUTES.LANDING);
  };

  const disableButton = useCallback(() => {
    if (answer.response === null) return true;
    return answer.response === CancellationValue.OTHER && !answer?.detail;
  }, [answer?.detail, answer.response]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton onBack={backButton} />
        <div className="vtmn-font-bold vtmn-typo_title-4">
          {t('cancellation.title')}
        </div>
      </div>
      <Spacer vertical={2} />
      <div className={styles.question}>
        {t('cancellation.question')}
      </div>
      <Spacer vertical={2} />
      <div className={styles.answerContainer}>
        <Question
          setAnswer={() => setAnswer({ response: CancellationValue.NOT_USED })}
          question={t('cancellation.response.1')}
          inputName="choice-1"
        />
        <Question
          setAnswer={() => setAnswer({ response: CancellationValue.SITUATION_CHANGED })}
          question={t('cancellation.response.2')}
          inputName="choice-2"
        />
        <div className={styles.answerInput}>
          <input
            className="vtmn-radio-button"
            type="radio"
            id="choice-3"
            name="favorite-choice"
            onChange={() => setAnswer({ response: CancellationValue.NOT_SATISFIED })}
          />
          <label className="vtmn-font-bold" htmlFor="choice-3">{t('cancellation.response.3')}</label>
          <div>
            <div>{t('cancellation.response.say_more')}</div>
            <TextInput
              icon="home-fill"
              identifier="vtmn-input"
              multiline
              onIconClick={() => null}
              placeholder={t('cancellation.response.not_satisfied')}
              onChange={(event) => setAnswer({ ...answer, notSatisfied: event.target.value })}
              disabled={answer.response !== CancellationValue.NOT_SATISFIED}
              value={answer.notSatisfied ?? ''}
            />
          </div>
        </div>
        <div className={styles.answerInput}>
          <input
            className="vtmn-radio-button"
            type="radio"
            id="choice-4"
            name="favorite-choice"
            onChange={() => setAnswer({ response: CancellationValue.OTHER })}
          />
          <label className="vtmn-font-bold" htmlFor="choice-4">{t('cancellation.response.4')}</label>
          <div>
            <div>{t('cancellation.response.say_more')}</div>
            <TextInput
              icon="home-fill"
              identifier="vtmn-input"
              multiline
              onIconClick={() => null}
              placeholder={t('cancellation.response.other')}
              onChange={(event) => setAnswer({ ...answer, detail: event.target.value })}
              disabled={answer.response !== CancellationValue.OTHER}
              value={answer.detail ?? ''}
            />
          </div>
        </div>
      </div>
      <Spacer vertical={2} />
      <div className={styles.button}>
        <button disabled={disableButton()} onClick={submit} className="vtmn-btn">{t('cancellation.cancel')}</button>
      </div>
    </div>
  );
};

export default Cancellation;
