import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  MenuItem, Select, InputBase, styled,
} from '@mui/material';
import classes from './styles.module.scss';
import type { Size } from '@/types/Component';

type SizeSelectorProps = {
  sizes: Size[],
  onUpdateSize: (size: Size) => void
  initialSizeSelected: Size | null,
  showTitle?: boolean,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    margin: 0,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const SizeSelector: FC<SizeSelectorProps> = ({
  sizes, onUpdateSize, initialSizeSelected, showTitle = true,
}) => {
  const { t } = useTranslation();

  const [sizeSelected, setSizeSelected] = useState<Size | null>(initialSizeSelected);

  function updateSize(size: Size) {
    if (size.maxQuantity > 0) {
      setSizeSelected(size);
      onUpdateSize(size);
    }
  }

  return (
    <>
      {showTitle && (
        <div className={classNames('vtmn-typo_text-2', classes.size_information)}>
          {t('size.size')}
          <span className="vtmn-font-bold">{sizeSelected?.size}</span>
        </div>
      )}
      <div className={classes.size_card}>
        <Select
          id="size"
          onChange={(event) => updateSize(JSON.parse(event.target.value as string))}
          placeholder={t('size.selection')}
          input={<BootstrapInput />}
          value={sizeSelected || ''}
          renderValue={(value: Size) => {
            if (!value.size) return t('size.selection');
            return value.size;
          }}
          displayEmpty
        >
          <MenuItem disabled value="">
            {t('size.selection')}
          </MenuItem>
          {sizes.map((size: Size) => (
            <MenuItem
              key={size.itemId}
              disabled={size.maxQuantity === 0}
              value={JSON.stringify(size)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>{size.size}</div>
              {size.maxQuantity === 0 ? (
                <div className={classes.not_available}>{t('size.not_available')}</div>
              ) : (
                <div className={classes.available}>{t('size.available')}</div>
              )}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  );
};

export default SizeSelector;
