import UseCaseInterface from './UseCaseInterface';
import { SelectionRepository } from '@/repositories/SelectionRepository';
import type { LockerItem } from '@/types/Locker';
import { LockerItemBuilder } from '@/builder/LockerItemBuilder';

class GetUserSelectionUseCase extends UseCaseInterface<any, LockerItem[]> {
  async execute(): Promise<LockerItem[]> {
    return (await SelectionRepository.getUserSelection()).map((item) => new LockerItemBuilder().GetLockerItem(item));
  }
}

export default new GetUserSelectionUseCase();
