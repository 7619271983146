import type { AxiosResponse } from 'axios';
import Api from '../../Api';
import type { LockerHistoryFromBack as LockerHistoryType } from '@/types/Locker';

class LockerHistory extends Api {
  protected getBaseUrl(): string {
    return 'locker_history';
  }

  public get(page_nb: number): Promise<AxiosResponse<LockerHistoryType>> {
    return this.axiosInstance.get<LockerHistoryType>('', {
      params: {
        page_nb,
      },
    });
  }
}

export default LockerHistory;
