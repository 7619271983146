import type { Bundle as BundleType, BundleFromBack, ModelLite } from '@/types/Bundle';
import { ModelRepository } from '@/repositories';
import { ModelBuilder } from '@/builder/ModelBuilder';

export class BundlerBuilder {
  public async builderBundle(bundlesFromBack: BundleFromBack[]): Promise<BundleType[]> {
    const bundlePromises = bundlesFromBack.map(async (bundle) => {
      const modelsPromises = bundle.models.map(async (bundleModel) => ({
        model: await this.buildModel(bundleModel.model.model_code, bundleModel.model.super_model_code),
        isModelInvest: bundleModel.is_model_invest,
      }));

      const models = await Promise.all(modelsPromises);

      return {
        models,
        title: bundle.title,
        ranking: bundle.ranking,
      };
    });

    return Promise.all(bundlePromises);
  }

  private async buildModel(modelCode: string, superModelCode: string): Promise<ModelLite> {
    const modelRepository = new ModelRepository();
    const modelBuilder = new ModelBuilder();

    const modelfromBack = await modelRepository.GetModelFromBack(modelCode, superModelCode);
    const stocks = await modelRepository.GetStockForModel(modelCode, superModelCode);

    const modelLight = modelBuilder.GetModelLight(modelfromBack, stocks);
    const packshot = modelBuilder.GetPackshot(modelfromBack.pack_shot);
    const colors = modelBuilder.GetColors(modelfromBack.declinations, modelfromBack.model_code);
    const reviews = {
      reviews: {
        globalRating: modelfromBack.reviews?.average_rating,
        nbReviews: modelfromBack.reviews?.nb_reviews,
      },
    };

    return {
      ...modelLight,
      ...packshot,
      ...colors,
      ...reviews,
    };
  }
}
