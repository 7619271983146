import type { AxiosResponse } from 'axios';
import Api from '../Api';
import type { Store } from '@/types/Store';

class Stores extends Api {
  protected getBaseUrl(): string {
    return 'stores';
  }

  public get(): Promise<AxiosResponse> {
    return this.axiosInstance.get<Store[]>('/');
  }
}

export default Stores;
