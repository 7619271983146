import UseCaseInterface from './UseCaseInterface';
import type { Gauge as GaugeType } from '@/types/Gauge';
import { UserRepository } from '@/repositories';

class GetUserGaugeUseCase extends UseCaseInterface<any, GaugeType> {
  async execute(): Promise<GaugeType> {
    const userRepository = new UserRepository();
    const gauge = await userRepository.getUserGauge();
    return {
      garageCurrentSize: gauge.garage_current_size,
      garageMaxSize: gauge.garage_max_size,
      subscriptionType: gauge.subscriptionType,
    };
  }
}

export default new GetUserGaugeUseCase();
