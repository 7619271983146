import UseCaseInterface from './UseCaseInterface';
import { SelectionRepository } from '@/repositories/SelectionRepository';

class ValidateSelectionUseCase extends UseCaseInterface<any, any> {
  execute(): Promise<any> {
    return SelectionRepository.validateSelection();
  }
}

export default new ValidateSelectionUseCase();
