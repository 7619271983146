import type { FC } from 'react';
import Head from './Head';
import Body from './Body';
import type { CookiesTitleContent } from '@/types/Config';

type Props = {
  cookies: CookiesTitleContent;
};

const Desktop: FC<Props> = ({ cookies }) => (
  <table>
    <Head cookieTitle={cookies.title} />
    <tbody>
      { cookies.cookies.map((cookie, index) => (
        <Body cookie={cookie} key={index} />
      ))}
    </tbody>
  </table>
);

export default Desktop;
