import type { FC } from 'react';
import CloseLine from '@vtmn/icons/dist/vitamix/svg/close-line.svg';
import classNames from 'classnames';
import classes from './styles.module.scss';

type ExpandButtonProps = {
  expanded: boolean
};

const ExpandButton: FC<ExpandButtonProps> = ({ expanded }) => (
  <div className={classNames(classes.root, { [classes.expanded]: expanded })}>
    <img src={CloseLine} alt="toggle" />
  </div>
);

export default ExpandButton;
