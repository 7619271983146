import type { FC } from 'react';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { Icon, Spacer } from '@/components';

type BigButtonProps = {
  onClick?: () => void;
  leftIcon?: VitamixId;
  text: string;
  children?: string;
};

const BigButton: FC<BigButtonProps> = ({
  onClick,
  leftIcon,
  text,
  children,
}) => (
  <button className={classes.root} onClick={onClick}>
    <div className={classes.content_container}>
      <div className={classes.main_content_container}>
        <div
          className={classNames(
            'vtmn-typo_text-1 vtmn-font-bold',
            classes.main_content,
          )}
        >
          {leftIcon ? (
            <Icon value={leftIcon} className={classes.left} />
          ) : null}
          <Spacer horizontal={2} />
          <span>{text}</span>
        </div>
        {children ? (
          <div
            className={classNames(
              'vtmn-font-normal vtmn-typo_text-3',
              classes.sub_content,
            )}
          >
            {children}
          </div>
        ) : null}
      </div>
    </div>
  </button>
);

export default BigButton;
