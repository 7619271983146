import { Bundle } from '@/core/api';
import type { BundleFromBack as BundleType } from '@/types/Bundle';

export class BundleRepository {
  static async getBundle(): Promise<BundleType[]> {
    const bundle = await new Bundle().get().then(({ data }) => data);
    return bundle;
  }

  static async getBundleByModelCode(modelCode: string): Promise<BundleType> {
    return new Bundle().getByModelCode(modelCode).then(({ data }) => data);
  }
}
