export enum ROUTES {
  HOME_PAGE = '/home',
  LANDING = '/landing',
  CGL = '/cgl',
  CGU = '/cgu',
  CGA = '/cga',
  LEGAL_NOTICE = '/mentions',
  PERSONAL_DATA = '/donnees-personnelles',
  CONTACT = '/contact',
  COOKIES = '/cookies',
  FAQ = '/faq',
  ACCOUNT = '/account',
  SEARCH = '/search',
  PRODUCT = '/product/:super_model_code/:model_code',
  LOCKER = '/locker',
  PRODUCT_LIST = '/product-list/:categoryId',
  RETURN_PAGE = '/return',
  SUBSCRIPTION_SELECTION = '/subscription-selection',
  ENGAGEMENT = '/engagement',
  SUBSCRIBE = '/subscribe',
  CANCELLATION = '/cancellation',
  RESULT_SEARCH = '/result-search/:search',
  CLOTURE = '/cloture',
}
