import type { FC } from 'react';
import { VtmnToggle } from '@vtmn/react';

type Props = {
  identifier: string,
  readOnly: boolean,
  checked: boolean,
  disabled: boolean,
};

const Toggle: FC<Props> = ({
  identifier, readOnly, checked, disabled,
}) => (
  <VtmnToggle
    identifier={identifier}
    readOnly={readOnly}
    checked={checked}
    disabled={disabled}
  />
);

export default Toggle;
