import styles from '@/pages/Cancellation/styles.module.scss';

interface Props {
  setAnswer: () => void,
  question: string,
  inputName: string,
}

const Question = ({ setAnswer, question, inputName }: Props) => (
  <div className={styles.answer}>
    <input
      className="vtmn-radio-button"
      type="radio"
      id={inputName}
      name="favorite-choice"
      onChange={setAnswer}
    />
    <label className="vtmn-font-bold" htmlFor={inputName}>{question}</label>
  </div>
);

export default Question;
