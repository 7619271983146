import UseCaseInterface from '@/usecases/UseCaseInterface';
import { ConfigRepository } from '@/repositories';
import type { Cookies } from '@/types/Config';
import SessionStorage from '@/utils/SessionStorage';

class GetCookiesUseCase extends UseCaseInterface<any, Cookies> {
  async execute(): Promise<Cookies> {
    const saved = SessionStorage.getCookies();
    if (!saved) {
      const cookies = await ConfigRepository.getCookies();
      SessionStorage.setCookies(JSON.stringify(cookies));
      return cookies;
    }
    return JSON.parse(saved);
  }
}
export default new GetCookiesUseCase();
