import type { FC } from 'react';
import { TopBarDesktop } from '@/components';
import classes from './styles.module.scss';
/* import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '@/hooks/useTracking';
import classes from './styles.module.scss';
import type { Category as CategoryType } from '@/types/Category';
import GetCategoriesUseCase from '@/usecases/GetCategoriesUseCase';
import NavBarSport from './NavBarSport';
import { TopBarDesktop, Icon } from '@/components';
import { ROUTES } from '@/pages/routes';
import { CategoryContext } from '@/context/Category/CategoryProvider';
import { LanguageContext } from '@/context/Language/LanguageProvider';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import LocalStorage from '@/utils/LocalStorage';
import { ConfigContext } from '@/context/Config/ConfigProvider';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { AuthState } from '@/context/Auth/AuthState';
import { RightsState } from '@/context/Rights/RightsState';

type NavBarDesktopProps = {
  openOrCloseNavBarSport: (isOpen: boolean) => void;
};

const NavBarDesktop: FC<NavBarDesktopProps> = () => {
  const navigateTo = useNavigate();
  const { isAuth } = useContext(AuthContext);
  const { isActive } = useContext(RightsContext);
  const { clickEvent } = useTracking();
  const [allCategories, setAllCategories] = useState<CategoryType[]>();
  const [categories, setCategories] = useState<CategoryType[]>();
  const [categorySelect, setCategorySelect] = useState<CategoryType>();
  const { goToFirstCategory, setGoToFirstCategory } = useContext(CategoryContext);
  const { banCategories, categoriesDictionary } = useContext(ConfigContext);
  const { updateLanguage, setUpdateLanguage } = useContext(LanguageContext);

  const { setIsError } = useContext(ErrorManagementContext);

  function getChildren(parentId: string) {
    if (!allCategories) return false;
    return (allCategories.filter((c) => c.parentId === parentId)).length > 0;
  }

  function openOrCloseChildrenCategory(category: CategoryType, trackOpenTree: boolean) {
    if (!getChildren(category.categoryId)) {
      navigateTo(`${ROUTES.PRODUCT_LIST.replace(':categoryId', category.categoryId)}`);
      GoogleAnalytics.hit(GaEvents.CLICK, {
        label: GA_LABELS.CATALOG.VIEW_ITEM_LIST,
        item_category: category.categoryId,
        category_name: category.label,
      });
      openOrCloseNavBarSport(false);
      setCategorySelect(undefined);
    } else if (!categorySelect || category.categoryId !== categorySelect.categoryId) {
      if (trackOpenTree) clickEvent(MATOMO_CATEGORY.HEADER.TREE);
      openOrCloseNavBarSport(true);
      setCategorySelect(category);
    } else {
      openOrCloseNavBarSport(false);
      setCategorySelect(undefined);
    }
  }

  useEffect(() => {
    (async () => {
      setGoToFirstCategory(false);
      if (!categories || !goToFirstCategory) return;
      if (!categoriesDictionary || categoriesDictionary.length === 0) {
        setCategorySelect(categories[0]);
        return;
      }
      const category = categories.find((c) => c.categoryId === categoriesDictionary[0].id && !banCategories?.includes(c.categoryId));
      setCategorySelect(category || categories[0]);
    })();
  }, [goToFirstCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (!updateLanguage) return;
      LocalStorage.clearCategories();
      await getCategories();
      setUpdateLanguage(false);
    })();
  }, [updateLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategories = useCallback(async () => {
    try {
      const categories = await GetCategoriesUseCase.execute();
      if (categories) setAllCategories(categories);
    } catch {
      setIsError(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      await getCategories();
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const seeMenu = useMemo(() => isAuth === AuthState.CONNECTED && isActive === RightsState.ACTIVE, [isActive, isAuth]);

  useEffect(() => {
    (async () => {
      if (!allCategories) return [];
      const firstCategory = allCategories.find((c) => c.parentId === null);
      if (!firstCategory) return false;
      if (categoriesDictionary) {
        const cats = [] as CategoryType[];
        categoriesDictionary.forEach((category) => {
          const cat = allCategories.find((c) => c.parentId === firstCategory.categoryId && c.categoryId === category.id && !banCategories?.includes(c.categoryId));
          if (!cat) return;
          cats.push(cat);
        });
        setCategories(cats);
        return false;
      }
      setCategories(allCategories.filter((c) => c.parentId === firstCategory.categoryId));
    })();
  }, [allCategories, categoriesDictionary]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <TopBarDesktop />
      <div className={classes.navBar}>
        { seeMenu && categories && categories.map((c, index) => (
          <div
            onClick={() => openOrCloseChildrenCategory(c, true)}
            key={index}
            className={classNames(classes.label, { [classes.category_select]: categorySelect && categorySelect.categoryId === c.categoryId })}
          >
            <div className="vtmn-typo_text-3">{c.label}</div>
            { getChildren(c.categoryId)
              && (
              <Icon
                value="chevron-down-fill"
                variant={categorySelect && categorySelect.categoryId === c.categoryId ? 'brand' : 'default'}
                size={16}
                className={classNames(classes.icon, { [classes.icon_select]: categorySelect && categorySelect.categoryId === c.categoryId })}
              />
              )}
          </div>
        ))}
      </div>
      { categorySelect && allCategories
        && (
        <NavBarSport
          categoryParent={categorySelect}
          categories={allCategories}
          openOrCloseNavBarSport={() => openOrCloseChildrenCategory(categorySelect, false)}
        />
        )}
    </div>
  );
}; */

const NavBarDesktop: FC = () => (
  <div className={classes.root}>
    <TopBarDesktop />
  </div>
);

export default NavBarDesktop;
