import moment from 'moment';
import type { ReviewsFromBack, GlobalReviewWithList } from '@/types/Reviews';

export class ReviewsBuilder {
  public GetReviews(reviewsFromBack: ReviewsFromBack): GlobalReviewWithList {
    if (!reviewsFromBack) return { globalRating: undefined, nbReviews: 0, reviews: [] };
    return {
      globalRating: reviewsFromBack.average_rating,
      nbReviews: reviewsFromBack.nb_reviews,
      reviews: reviewsFromBack.reviews?.map((r) => ({
        rating: r.rating,
        username: r.username,
        createdAt: moment(new Date(r.publisher_date)).format('DD/MM/YYYY'),
        title: r.title,
        useSince: r.used_since,
        message: r.comment,
        reviewsDetail: r.attributes,
      })),
    };
  }
}
