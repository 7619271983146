import UseCaseInterface from './UseCaseInterface';
import { LockerRepository } from '@/repositories/LockerRepository';
import type { LockerItem } from '@/types/Locker';
import { LockerItemBuilder } from '@/builder/LockerItemBuilder';

class GetUserEquipementUseCase extends UseCaseInterface<any, LockerItem[]> {
  async execute(): Promise<LockerItem[]> {
    return (await LockerRepository.getEquipment()).map((item) => new LockerItemBuilder().GetLockerItem(item));
  }
}

export default new GetUserEquipementUseCase();
