import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import type { FC } from 'react';
import { Spacer, WPCButton } from '@/components';
import classes from './styles.module.scss';
import { useResponsive } from '@/hooks/useResponsive';
import Assets from '@/core/api/Assets';

const visuals = Assets.getAssets('/landing/whatIs.png');

const WhatIs: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div className={classes.root}>
      <img src={visuals} width={450} alt="" loading="lazy" />
      <div className={classes.container}>
        <span className={classNames('vtmn-typo_title-2', classes.explanation)}>
          {t('landing.what_is.title')}
        </span>
        <span>{t('landing.what_is.description')}</span>
        {!isMobile && (
          <Link to="https://youtu.be/baeHfcujxKU?" target="_blank">
            <WPCButton onAction={() => { }} variant="primary">
              {t('actions.learn_more')}
            </WPCButton>
          </Link>
        )}
      </div>
      {isMobile && (
        <Link to="https://youtu.be/baeHfcujxKU?" target="_blank">
          <WPCButton
            size="stretched"
            onAction={() => {}}
            variant="primary"
          >
            {t('actions.learn_more')}
          </WPCButton>
        </Link>
      )}
      {isMobile && <Spacer vertical={2} />}
    </div>
  );
};

export default WhatIs;
