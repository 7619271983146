import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useResponsive } from '@/hooks/useResponsive';
import { Spacer } from '@/components';
import type { Service } from '@/types/Service';
import Card from './Card';
import classes from './styles.module.scss';

type Props = {
  className?: string,
};

const CardsService: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const pagination = {
    clickable: true,
    renderBullet(index: number, className: string) {
      return `<span class="${className}">' </span>`;
    },
    dynamicBullets: true,
    dynamicMainBullets: 5,
    clickableClass: (classes.swipperDots),
  };

  const services: Service[] = [
    { type: 'location', link_nl: 'https://www.decathlon.be/nl/Rent_lp-RCGASH', link_fr: 'https://www.decathlon.be/fr/Rent_lp-RCGASH' },
    { type: 'seconde_life', link_nl: 'https://www.decathlon.be/nl/tweedehands', link_fr: 'https://www.decathlon.be/fr/seconde-vie' },
    {
      type: 'repurchase',
      link_nl: 'https://www.decathlon.be/nl/Buy-back_lp-YHWEUD?intcmp=intcmp:HPFloorCirculaire-Buyback-NL-&type=intcmp',
      link_fr: 'https://www.decathlon.be/fr/Buy-back_lp-YHWEUD?intcmp=intcmp:HPFloorCirculaire-Buyback-NL-&type=intcmp',
    },
    {
      type: 'repair',
      link_nl: 'https://www.decathlon.be/nl/Workshop-repair_lp-8AGCFS',
      link_fr: 'https://www.decathlon.be/fr/Workshop-repair_lp-8AGCFS',
    },
  ];

  return (
    <div className={className}>
      <div className="vtmn-typo_title-4">
        { t('homepage.cloture.list_services.title') }
      </div>
      <Spacer vertical={2} />
      <div className="vtmn-typo_text-1">
        {t('homepage.cloture.list_services.subtitle')}
      </div>
      <Spacer vertical={3} />
      { isMobile
        ? (
          <div className={classes.swiper_mobile}>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              slidesPerGroup={1}
              loop
              pagination={pagination}
              modules={[Pagination]}
              className={classes.swiper}
            >
              { services.map((service, index) => (
                <SwiperSlide key={index} className={classes.slide}>
                  <Card service={service} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <Grid container spacing={3}>
            { services.map((service, index) => (
              <Grid item xs={3} key={index}>
                <Card service={service} />
              </Grid>
            ))}
          </Grid>
        )}
    </div>
  );
};

export default CardsService;
