import type { FC } from 'react';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { Spacer, Icon } from '@/components';

type FooterButtonProps = {
  title: string;
  icon?: VitamixId;
  rightIcon?: boolean;
  isBold?: boolean,
  rightIconName?: VitamixId
};

const FooterButton: FC<FooterButtonProps> = ({
  title,
  icon,
  rightIcon = false,
  isBold = false,
  rightIconName = 'chevron-right-fill',
}) => (
  <div className={classes.root}>
    <div className={classes.icon}>
      {icon ? (
        <>
          <Icon value={icon} />
          <Spacer horizontal={2} />
        </>
      ) : null}
      <div className={classnames('vtmn-typo_text-2', isBold && 'vtmn-font-bold')}>
        {title}
      </div>
    </div>
    {rightIcon && <Icon value={rightIconName} variant="default" />}
  </div>
);

export default FooterButton;
