import type { Dispatch, SetStateAction } from 'react';
import {
  createContext, useCallback, useMemo, useState,
} from 'react';

import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';

type SizesContextType = {
  sizes: string[],
  selectSizes: (size: string) => void,
  resetSizes: () => void,
  isSizesOpen: boolean,
  handleOpen: () => void,
  setIsSizesOpen: Dispatch<SetStateAction<boolean>>,
};

export const SizesContext = createContext<SizesContextType>({
  sizes: [],
  selectSizes: () => null,
  resetSizes: () => null,
  isSizesOpen: false,
  handleOpen: () => null,
  setIsSizesOpen: () => null,
});

export const SizesProvider = ({ children }: { children: JSX.Element }) => {
  const [sizes, setSizes] = useState<string[]>([]);
  const [isSizesOpen, setIsSizesOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!isSizesOpen) {
      GoogleAnalytics.hit(GaEvents.CLICK, {
        label: GA_LABELS.CATALOG.USE_FILTER,
        filter_terms: 'SIZE',
      });
    }
    setIsSizesOpen((isOpen) => !isOpen);
  }, [isSizesOpen]);

  const selectSizes = useCallback((newSize: string) => {
    setSizes((sizes) => {
      if (sizes.find((size) => size === newSize)) {
        return sizes.filter((size) => size !== newSize);
      }
      return [...sizes, newSize];
    });
  }, []);

  const resetSizes = useCallback(() => {
    setSizes([]);
  }, []);

  const value = useMemo(() => ({
    sizes,
    selectSizes,
    resetSizes,
    isSizesOpen,
    handleOpen,
    setIsSizesOpen,
  }), [handleOpen, isSizesOpen, sizes, resetSizes, selectSizes]);

  return (
    <SizesContext.Provider value={value}>
      {children}
    </SizesContext.Provider>
  );
};
