import type { FC } from 'react';
import { VtmnModalActions } from '@vtmn/react';

type Props = {
  children?: JSX.Element | JSX.Element[];
  className?: string,
};

const ModalActions: FC<Props> = ({ children, className }) => (
  <VtmnModalActions className={className}>
    { children }
  </VtmnModalActions>
);

export default ModalActions;
