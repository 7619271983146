import type { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { Spacer, Icon } from '@/components';
import { useResponsive } from '@/hooks/useResponsive';
import Assets from '@/core/api/Assets';

const intro = Assets.getAssets('/engagement/intro.png');
const mobileIntro = Assets.getAssets('/engagement/mobile_intro.png');
const constat = Assets.getAssets('/engagement/constat.png');

const voluntary = Assets.getAssets('/engagement/voluntary.png');
const mobileVoluntary = Assets.getAssets('/engagement/mobile_voluntary.png');
const offer = Assets.getAssets('/engagement/offer.png');
const engagement = Assets.getAssets('/engagement/engagement.png');
const whoWeAre = Assets.getAssets('/engagement/whoWeAre.png');

const OurEngagement: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div className={styles.container}>
      <div className="vtmn-typo_title-2">{t('engagement.title')}</div>
      <Spacer vertical={isMobile ? 3 : 6} />
      <div className={styles.mainContent}>
        <div className={classnames(styles.pictureLeft, styles.card)}>
          <div>
            <div className="vtmn-typo_title-5">{t('engagement.intro.title')}</div>
            <Spacer vertical={1.25} />
            <div className="vtmn-typo_text-1">{t('engagement.intro.content')}</div>
          </div>
          <img src={isMobile ? mobileIntro : intro} height={197} alt="" />
        </div>

        <Spacer vertical={isMobile ? 3 : 4} />
        <div className={classnames(styles.infos)}>
          <div className={classnames(styles.card, styles.info)}>
            <div className={styles.picto}>
              <Icon
                size={24}
                value="group-line"
                variant="default"
              />
            </div>
            <div className="vtmn-typo_text-1"><Trans i18nKey="engagement.infos.info1" /></div>
          </div>
          <div className={classnames(styles.card, styles.info)}>
            <div className={styles.picto}>
              <Icon
                size={24}
                value="football-line"
                variant="default"
              />
            </div>
            <div className="vtmn-typo_text-1"><Trans i18nKey="engagement.infos.info2" /></div>
          </div>
          <div className={classnames(styles.card, styles.info)}>
            <div className={styles.picto}>
              <Icon
                size={24}
                value="thumb-up-line"
                variant="default"
              />
            </div>
            <div className="vtmn-typo_text-1"><Trans i18nKey="engagement.infos.info3" /></div>
          </div>
          <div className={classnames(styles.card, styles.info)}>
            <div className={styles.picto}>
              <Icon
                size={24}
                value="selected-by-decathlon-line"
                variant="default"
              />
            </div>
            <div className="vtmn-typo_text-1"><Trans i18nKey="engagement.infos.info4" /></div>
          </div>
        </div>

        <Spacer vertical={isMobile ? 8 : 11} />
        <div className={styles.pictureLeft}>
          <img src={constat} alt="" />
          <div>
            <div className="vtmn-typo_title-4"><Trans i18nKey="engagement.constat.title" /></div>
            <Spacer vertical={2} />
            <p className="vtmn-typo_text-1"><Trans i18nKey="engagement.constat.content1" /></p>
            <Spacer vertical={3} />
            <p className="vtmn-typo_text-1"><Trans i18nKey="engagement.constat.content2" /></p>
          </div>
        </div>
        <Spacer vertical={isMobile ? 0 : 3} />
        <div className={styles.pictureRight}>
          <div>
            <div className="vtmn-typo_title-4"><Trans i18nKey="engagement.voluntary.title" /></div>
            <Spacer vertical={2} />
            <div className="vtmn-typo_text-1">
              <Trans i18nKey="engagement.voluntary.content" />
            </div>
          </div>
          <img src={isMobile ? mobileVoluntary : voluntary} alt="" />
        </div>
        <Spacer vertical={5} />
        <div className={styles.pictureLeft}>
          <img src={offer} alt="" />
          <div>
            <div className="vtmn-typo_title-4"><Trans i18nKey="engagement.offer.title" /></div>
            <Spacer vertical={3} />
            <div className="vtmn-typo_text-1"><Trans i18nKey="engagement.offer.content" /></div>
          </div>
        </div>
        <div className={styles.pictureRight}>
          <div>
            <div className="vtmn-typo_title-4"><Trans i18nKey="engagement.engage.title" /></div>
            <Spacer vertical={2} />
            <div className="vtmn-typo_text-1">
              <ul>
                <li><Trans i18nKey="engagement.engage.content1" /></li>
                <li><Trans i18nKey="engagement.engage.content2" /></li>
                <li><Trans i18nKey="engagement.engage.content3" /></li>
                <li><Trans i18nKey="engagement.engage.content4" /></li>
                <li><Trans i18nKey="engagement.engage.content5" /></li>
              </ul>
            </div>
          </div>
          <img src={engagement} alt="" />
        </div>
        <Spacer vertical={5} />
        <div className={styles.pictureLeft}>
          <img src={whoWeAre} alt="" />
          <div>
            <div className="vtmn-typo_title-4"><Trans i18nKey="engagement.who_we_are.title" /></div>
            <Spacer vertical={3} />
            <div className="vtmn-typo_text-1">
              <Trans i18nKey="engagement.who_we_are.content1" />
              <Spacer vertical={3} />
              <Trans i18nKey="engagement.who_we_are.content2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurEngagement;
