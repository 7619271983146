import type { Dispatch, FC, SetStateAction } from 'react';
import {
  createContext, useMemo, useState,
} from 'react';

type FiltersContextType = {
  setApplyFilters: Dispatch<SetStateAction<boolean>>,
  applyFilters: boolean,
};

export const FiltersContext = createContext<FiltersContextType>({
  setApplyFilters: () => null,
  applyFilters: false,
});

export const FiltersProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [applyFilters, setApplyFilters] = useState(false);

  const value = useMemo(() => ({
    applyFilters,
    setApplyFilters,
  }), [applyFilters, setApplyFilters]);

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};
