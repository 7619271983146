import { createContext, useMemo, useState } from 'react';
import type { LockerItem } from '@/types/Locker';

export type ReturnContextType = {
  itemToReturn: LockerItem | undefined;
  setItemToReturn: (item: LockerItem) => void;
};

export const ReturnContext = createContext<ReturnContextType>({
  itemToReturn: undefined,
  setItemToReturn: () => null,
});

export const ReturnProvider = ({ children }: { children: JSX.Element }) => {
  const [itemToReturn, setItemToReturn] = useState<LockerItem>();

  const values = useMemo(() => ({
    itemToReturn,
    setItemToReturn,
  }), [itemToReturn, setItemToReturn]);

  return (
    <ReturnContext.Provider value={values}>{children}</ReturnContext.Provider>
  );
};
