import type { AxiosResponse } from 'axios';
import Api from '@/core/api/Api';

class Search extends Api {
  protected getBaseUrl(): string {
    return 'researches';
  }

  public searchProducts(search: string): Promise<AxiosResponse> {
    return this.axiosInstance.get(`/queries?query=${search}`);
  }

  public searchPage(search: string): Promise<AxiosResponse> {
    return this.axiosInstance.get(`/?query=${search}`);
  }
}

export default Search;
