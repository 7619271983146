import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

type Props = {
  value: string;
  className?: string;
};

const ChildTable: FC<Props> = ({ value, className }) => (
  <tr className={classes.child_cell}>
    <td className={classNames(classes.td, className)}>
      {value}
    </td>
  </tr>
);

export default ChildTable;
