import type React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { RadioButton, Spacer } from '@/components';
import Assets from '@/core/api/Assets';

const CompleteIcon = Assets.getAssets('/return/complete.svg');
const CleanIcon = Assets.getAssets('/return/clean.svg');
const GoodStateIcon = Assets.getAssets('/return/good_state.svg');

type StateProps = {
  mode: 'complete' | 'clean' | 'state';
  current?: boolean;
  onSelect: (value: boolean) => void;
};

const State: React.FC<StateProps> = ({ mode, current, onSelect }) => {
  const { t } = useTranslation();

  const currentIcon = () => {
    switch (mode) {
      case 'complete':
        return CompleteIcon;
      case 'clean':
        return CleanIcon;
      case 'state':
        return GoodStateIcon;
      default:
        return CompleteIcon;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.infos}>
          <span className="vtmn-typo_text-1 vtmn-font-bold">
            {t(`return.state.${mode}_title`)}
          </span>
          <span className="vtmn-typo_text-3">
            {t(`return.state.${mode}_subtitle`)}
          </span>
        </div>
        <div className={classes.radios}>
          <RadioButton
            key={0}
            text={t('return.no')}
            selected={current === false}
            onSelect={() => onSelect(false)}
            iconLeft="thumb-down-line"
            background="white"
          />
          <RadioButton
            key={1}
            text={t('return.yes')}
            selected={current === true}
            onSelect={() => onSelect(true)}
            iconLeft="thumb-up-line"
            background="white"
          />
        </div>
      </div>
      {current === false ? (
        <>
          <Spacer vertical={3} />
          <div className={classes.extended}>
            <img src={currentIcon()} className={classes.icon} alt="icon" />
            <div className={classes.extended_content}>
              <span className="vtmn-typo_text-3">
                {t(`return.state.${mode}_extended_desc`)}
              </span>
              <Spacer vertical={2} />
              <span className="vtmn-typo_text-3">
                {t(`return.state.${mode}_extended_desc2`)}
              </span>
            </div>
          </div>
          <Spacer vertical={3} />
        </>
      ) : null}
    </div>
  );
};

export default State;
