import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { Service } from '@/types/Service';
import classes from './styles.module.scss';
import { Spacer, WPCButton } from '@/components';

type Props = {
  service: Service,
};

const Card : FC<Props> = ({ service }) => {
  const { t, i18n } = useTranslation();

  const openLink = () => {
    if (i18n.language === 'fr') window.open(service.link_fr, '_blank');
    else window.open(service.link_nl, '_blank');
  };

  return (
    <button className={classes.card} onClick={openLink}>
      <div className={classNames('vtmn-typo_title-3', classes.title)}>
        {t(`homepage.cloture.list_services.services.${service.type}.title`)}
      </div>
      <Spacer vertical={3} />
      <div>
        {t(`homepage.cloture.list_services.services.${service.type}.description`)}
      </div>
      <Spacer vertical={3} />
      <WPCButton
        className={classes.button}
        variant="ghost"
        onAction={openLink}
      >
        {t('actions.discover')}
      </WPCButton>
    </button>
  );
};

export default Card;
