import UseCaseInterface from './UseCaseInterface';
import { LockerRepository } from '@/repositories/LockerRepository';
import type { LockerItem, ReturnInfos } from '@/types/Locker';

type ReturnEquipmentType = {
  itemToReturn: LockerItem;
  returnInfos: ReturnInfos;
};

class ReturnEquipmentsUseCase extends UseCaseInterface<ReturnEquipmentType, any> {
  execute(toReturn: ReturnEquipmentType): Promise<any> {
    return LockerRepository.returnEquipments([
      {
        locker_items_uuid: toReturn.itemToReturn.uuid,
        satisfaction: toReturn.returnInfos.satisfaction.value,
        is_clean: toReturn.returnInfos.clean,
        is_complete: toReturn.returnInfos.complete,
        looks_fine: toReturn.returnInfos.goodState,
        usage: toReturn.returnInfos.usage.value.toString(),
        duration: toReturn.returnInfos.time.value.toString(),
      },
    ]);
  }
}

export default new ReturnEquipmentsUseCase();
