import UseCaseInterface from '@/usecases/UseCaseInterface';
import { UserRepository } from '@/repositories';

class GetUserRightsUseCase extends UseCaseInterface<any, string> {
  async execute(): Promise<string> {
    const userRepository = new UserRepository();
    const rights = await userRepository.getUserRights();
    return rights.subscription_status;
  }
}

export default new GetUserRightsUseCase();
