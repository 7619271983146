import type { FC } from 'react';
import classes from './styles.module.scss';
import { Help, Page } from '@/components';

const Contact: FC = () => (
  <Page className={classes.root}>
    <Help />
  </Page>
);

export default Contact;
