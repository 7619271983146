import UseCaseInterface from '@/usecases/UseCaseInterface';
import type { Store } from '@/types/Store';
import { StoresRepository } from '@/repositories/StoresRepository';

class GetStoresUseCase extends UseCaseInterface<void, Store[]> {
  async execute(): Promise<Store[]> {
    return StoresRepository.getStores();
  }
}

export default new GetStoresUseCase();
