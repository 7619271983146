import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useContext } from 'react';
import classes from './styles.module.scss';
import Title from './Title';
import WhatIs from './WhatIs';
import NotNecessary from './NotNecessary';
import Details from './Details';
import { ConfigContext } from '@/context/Config/ConfigProvider';

type CookiesProps = {
  showCookies: () => void;
};

const Cookies: FC<CookiesProps> = ({ showCookies }) => {
  const { cookies } = useContext(ConfigContext);

  return (cookies) ? (
    <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={12}> <Title showCookies={showCookies} /> </Grid>
        <Grid item xs={12}> <WhatIs /> </Grid>
        <Grid item xs={12}> <NotNecessary /> </Grid>
        <Grid item xs={12}> <Details content={cookies} /> </Grid>
      </Grid>
    </div>
  ) : null;
};

export default Cookies;
