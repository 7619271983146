import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { Icon } from '@/components';

type Props = {
  rateCount?: number,
  rateStars?: string,
};
export const Rating: FC<Props> = ({ rateCount, rateStars }) => (
  <div className={classNames('vtmn-typo_caption-1 vtmn-font-bold', classes.rating)}>
    <Icon
      size={16}
      value="star-fill"
      variant="default"
    />
    {rateStars}/5
    <span className="vtmn-font-normal">
      ({rateCount})
    </span>
  </div>
);
