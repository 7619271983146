import {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import type {
  LandingContent,
  CookieContent,
  Category,
  TestimonyDataContent,
} from '@/types/Config';
import type { SupportedLanguage } from '@/types/Language';
import GetCookiesUseCase from '@/usecases/GetCookiesUseCase';
import GetLandingUseCase from '@/usecases/GetLandingUseCase';
import GetTestimonialsUseCase from '@/usecases/GetTestimonialsUseCase';
import GetBanCategoriesUseCase from '@/usecases/GetBanCategoriesUseCase';
import GetDictionaryCategoryUseCase from '@/usecases/GetDictionaryCategoryUseCase';

export type ConfigContextType = {
  cookies : CookieContent | undefined,
  landing: LandingContent | undefined,
  banCategories: string[] | undefined,
  categoriesDictionary: Category[] | undefined,
  testimonials: TestimonyDataContent[] | undefined,
};

export const ConfigContext = createContext<ConfigContextType>({
  cookies: undefined,
  landing: undefined,
  banCategories: [],
  categoriesDictionary: [],
  testimonials: [],
});

export const ConfigProvider = ({ children }: { children: JSX.Element }) => {
  const { i18n } = useTranslation();

  const [cookies, setCookies] = useState<CookieContent>();
  const [landing, setLanding] = useState<LandingContent>();
  const [testimonials, setTestimonials] = useState<TestimonyDataContent[]>();
  const [banCategories, setBanCategories] = useState<string[]>();
  const [categoriesDictionary, setCategoriesDictionary] = useState<Category[]>();

  useEffect(() => {
    (async () => {
      const [
        cookies,
        landing, banCategories, categories, testimonials] = await Promise.all(
        [
          GetCookiesUseCase.execute(),
          GetLandingUseCase.execute(),
          GetBanCategoriesUseCase.execute(),
          GetDictionaryCategoryUseCase.execute(),
          GetTestimonialsUseCase.execute(),
        ],
      );

      setCookies(cookies[i18n.language as SupportedLanguage] || cookies.fr);
      setLanding(landing);
      setBanCategories(banCategories);
      setCategoriesDictionary(categories);
      setTestimonials(testimonials[i18n.language as SupportedLanguage] || testimonials.fr);
    })();
  }, [i18n.language]);

  const value = useMemo(() => ({
    cookies, landing, banCategories, categoriesDictionary, testimonials,
  }), [cookies, landing, banCategories, categoriesDictionary, testimonials]);

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
