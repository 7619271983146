import UseCaseInterface from '@/usecases/UseCaseInterface';
import type { LockerItem } from '@/types/Locker';
import { LockerRepository } from '@/repositories/LockerRepository';
import { LockerItemBuilder } from '@/builder/LockerItemBuilder';

class GetUserLockerUseCase extends UseCaseInterface<any, LockerItem[]> {
  async execute(): Promise<LockerItem[]> {
    const lockerItems = await LockerRepository.getMyLocker();
    return lockerItems.map((lockerItem) => new LockerItemBuilder().GetLockerItem(lockerItem));
  }
}

export default new GetUserLockerUseCase();
