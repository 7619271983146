import moment from 'moment';

class SortingUtils {
  public sortByDate(a: string, b: string, sorting: 'asc' | 'desc'): 1 | -1 | 0 {
    if (moment(sorting === 'asc' ? a : b).isBefore(sorting === 'asc' ? b : a)) return -1;
    return moment(sorting === 'asc' ? a : b).isAfter(sorting === 'asc' ? b : a) ? 1 : 0;
  }
}

export default new SortingUtils();
