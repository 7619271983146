import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import classes from './styles.module.scss';
import InfoToggle from '../InfoToggle';

type Props = {
  traduction: string,
  icon: string,
  className?: string,
  disabled: boolean,
};

const Mobile: FC<Props> = ({
  traduction, icon, className, disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} className={classNames(className, classes.root)}>
      <Grid xs={1} sm={0.6} item className={classes.icon}>
        <img src={icon} alt="Icon" />
      </Grid>
      <Grid item xs={11} container spacing={1}>
        <Grid item xs={12} className="vtmn-typo_title-5">
          { t(`cookies.what_is.${traduction}.title`) }
        </Grid>
        <Grid item xs={12}>
          { t(`cookies.what_is.${traduction}.description`) }
        </Grid>
        <Grid item xs={12}>
          <InfoToggle
            traduction={traduction}
            disabled={disabled}
            className={disabled ? 'info' : 'info_disabled'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Mobile;
