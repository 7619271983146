import UseCaseInterface from '@/usecases/UseCaseInterface';
import type { Bundle as BundleType } from '@/types/Bundle';
// import SessionStorage from '@/utils/SessionStorage';
import { BundleRepository } from '@/repositories';
import { BundlerBuilder } from '@/builder/BundlerBuilder';
// import Language from '@/service/Language/language';

class GetBundleUseCase extends UseCaseInterface<any, BundleType[]> {
  async execute(): Promise<BundleType[]> {
    // const lng = await Language.get();
    // const bundlesSave = SessionStorage.getBundle(lng);
    const bundleBuilder = new BundlerBuilder();
    // if (!bundlesSave) {
    return bundleBuilder.builderBundle(await BundleRepository.getBundle());
    // SessionStorage.setBundle(JSON.stringify(bundles), lng);
    // }
    // return JSON.parse(bundlesSave);
  }
}

export default new GetBundleUseCase();
