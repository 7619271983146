export const GA_LABELS = {
  CATALOG: {
    HIGHLIGHTED_PRODUCT: 'highlighted_sport',
    VIEW_ITEM_LIST: 'view_item_list',
    USE_FILTER: 'use_filter',
  },
  PRODUCT_DETAIL: {
    VIEW_ITEM: 'view_item',
    ADD_LOCKER: 'add_to_vestiaire',
    VIEW_REVIEW: 'view_reviews',
  },
};
