import UseCaseInterface from './UseCaseInterface';
import type { Category } from '@/types/Category';
import { CategoryRepository } from '@/repositories/CategoryRepository';

class GetCategoriesUseCase extends UseCaseInterface<any, Category[]> {
  async execute() : Promise<Category[]> {
    const categoryRepository = new CategoryRepository();
    return (await categoryRepository.GetCategories()).map((c) => ({
      parentId: c.parent_id,
      categoryId: c.category_id,
      label: c.label,
    }));
  }
}

export default new GetCategoriesUseCase();
