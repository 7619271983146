import type { AxiosResponse } from 'axios';
import Api from '../Api';

class Auth extends Api {
  protected getBaseUrl(): string {
    return 'authentication/';
  }

  public getToken(code: string | null, redirect_uri: string): Promise<AxiosResponse> {
    return this.axiosInstance.post('login', {
      code,
      redirect_uri,
    });
  }

  public getTokenWithRefreshToken(refreshToken: string): Promise<AxiosResponse> {
    return this.axiosInstance.post('login_with_refresh', {
      refresh_token: refreshToken,
    });
  }
}

export default Auth;
