import { Config } from '@/core/api';
import type {
  ConfigCategories, Cookies,
  LandingContent, Testimony,
} from '@/types/Config';

export class ConfigRepository {
  static async getCategories(): Promise<ConfigCategories[]> {
    return new Config().getCategories(REACT_APP_ENV === 'prod').then(({ data }) => data);
  }

  static async getBanCategories(): Promise<string[]> {
    return new Config().getBanCategories(REACT_APP_ENV === 'prod').then(({ data }) => data);
  }

  static async getLanding() : Promise<LandingContent> {
    return new Config().getLanding().then(({ data }) => data);
  }

  static async getCookies() : Promise<Cookies> {
    return new Config().getCookies().then(({ data }) => data);
  }

  static async getTestimonials() : Promise<Testimony> {
    return new Config().getTestimonials().then(({ data }) => data);
  }
}
