import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import i18n from 'i18next';
import { useTracking } from '@/hooks/useTracking';
import classes from './styles.module.scss';
import type { FAQ as FAQType } from '@/types/FAQ';

import { MATOMO_CATEGORY } from '@/constants/matomo';

import { FAQPage, WPCButton } from '@/components';
import { ROUTES } from '@/pages/routes';
import GetStaticFileUseCase from '@/usecases/GetStaticFileUseCase';
import { StaticFiles } from '@/constants/staticFiles';

const FAQ: FC = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { clickEvent } = useTracking();
  const [content, setContent] = useState<FAQType>();

  const goToContact = useCallback(() => {
    clickEvent(MATOMO_CATEGORY.FAQ_PAGE.CONTACT);
    navigateTo(ROUTES.CONTACT);
  }, [clickEvent, navigateTo]);

  const handleReportBug = (e:MouseEvent) => {
    e.preventDefault();
    window.open('mailto:team.wpc@decathlon.com', '_blank');
  };

  useEffect(() => {
    (async () => {
      const staticFile = await GetStaticFileUseCase.execute(StaticFiles.faq);
      const faq = JSON.parse(staticFile.content);
      // eslint-disable-next-line no-console
      console.log(staticFile);
      setContent({
        ...faq,
        title: staticFile.title,
      });
    })();
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      { content
        && (
          <FAQPage content={content}>
            <div className={classes.contact_container}>
              <h2 className={classNames('vtmn-font-bold', classes.contact_title)}>{t('faq.contact_title')}</h2>
              <div className={classes.contact_buttons_container}>
                <WPCButton onAction={goToContact} size="stretched" iconLeft="customer-service-line">
                  {t('faq.button_contact_text')}
                </WPCButton>
                <WPCButton onAction={handleReportBug} variant="primary" size="stretched" iconLeft="error-warning-line">
                  {t('faq.button_bug_report_text')}
                </WPCButton>
              </div>
            </div>
          </FAQPage>
        )}
    </div>
  );
};

export default FAQ;
