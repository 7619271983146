import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';

import Assets from '@/core/api/Assets';

const NoReviewImg = Assets.getAssets('/review/man.svg');
const NoReview: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img src={NoReviewImg} alt="NoReview" />
      <div className="vtmn-typo_title-4 vtmn-font-bold">
        {t('review.no_review.title')}
      </div>
      <div className={classes.description}>
        {t('review.no_review.description')}
      </div>
    </div>
  );
};

export default NoReview;
