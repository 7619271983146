import UseCaseInterface from './UseCaseInterface';
import { UserRepository } from '@/repositories';
import type { UserStoreAndSubscription } from '@/types/User';

class GetUserStoreAndSubscription extends UseCaseInterface<any, UserStoreAndSubscription> {
  async execute() : Promise<UserStoreAndSubscription> {
    const userRepository = new UserRepository();
    return userRepository.getUserStoreAndSubcription();
  }
}

export default new GetUserStoreAndSubscription();
