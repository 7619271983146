import Search from '@/core/api/Search';
import type { SearchFromBack } from '@/types/Search';

class SearchRepository {
  static async search(search: string): Promise<SearchFromBack> {
    return new Search().searchProducts(search).then(({ data }) => data);
  }

  static async researchPage(search: string): Promise<SearchFromBack> {
    return new Search().searchPage(search).then(({ data }) => data);
  }
}

export default SearchRepository;
