import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from './styles.module.scss';
import { Spacer, Icon } from '@/components';
import { ROUTES } from '@/pages/routes';
import { useResponsive } from '@/hooks/useResponsive';
import Assets from '@/core/api/Assets';

const Pool = Assets.getAssets('/notFound/pool.svg');
const NotFound: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div className={classes.root}>
      <div>
        { isMobile && <Spacer vertical={3} /> }
        <div className="vtmn-typo_title-1">
          { t('not_found.title') }
        </div>
        <Spacer vertical={3} />
        <div className="vtmn-typo_title-3">
          { t('not_found.subtitle') }
        </div>
        <Spacer vertical={3} />
        <div className="vtmn-typo_text-1">
          { t('not_found.description') }
        </div>
        <Spacer vertical={3} />
        <Link to={ROUTES.HOME_PAGE} className={classes.button_home}>
          <div className="vtmn-typo_text-1 vtmn-font-bold vtmn-text-content-action">
            { t('actions.home') }
          </div>
          <Icon value="chevron-right-fill" variant="default" size={16} className={classes.icon} />
        </Link>
        { isMobile && <Spacer vertical={10} /> }
      </div>
      <img src={Pool} alt="Pool" className={classes.img} />
    </div>
  );
};

export default NotFound;
