import type { FC } from 'react';
import {
  memo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import classes from './styles.module.scss';
import FooterButton from './Button';
import { ROUTES } from '@/pages/routes';
import LanguageSelector from '../LanguageSelector';
import { useResponsive } from '@/hooks/useResponsive';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import Assets from '@/core/api/Assets';
import { useTracking } from '@/hooks/useTracking';
import { Icon } from '@/components';

const logo = Assets.getAssets('/general/decathlonLogo.svg');

const Footer: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { clickEvent } = useTracking();
  const trackClickEvent = useCallback((eventCategory: string) => {
    clickEvent(eventCategory);
  }, [clickEvent]);

  return (
    <div className={classes.root}>
      <div className={classNames('vtmn-font-bold', classes.legal)}>
        <div>
          <Link to={ROUTES.CONTACT} onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.HELP)}>
            <FooterButton title={t('footer.help')} icon="customer-service-line" rightIcon isBold={isMobile} />
          </Link>
          {isMobile && (
            <>
              <div className={classes.divider} />
              <Link to={ROUTES.FAQ} onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.FAQ)}>
                <FooterButton title={t('footer.faq')} icon="question-line" rightIcon isBold={isMobile} />
              </Link>
            </>
          )}
          <div className={classes.divider} />
          <a
            href={t('footer.dkt_services')}
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
            onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.SERVICES)}
          >
            <FooterButton title={t('footer.decathlon_services')} rightIcon isBold={isMobile} />
          </a>
          <div className={classes.divider} />
          <Link to={ROUTES.CGA} onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.CGA)}>
            <FooterButton title={t('footer.CGA')} isBold={isMobile} />
          </Link>
          <div className={classes.divider} />
          <Link
            to={ROUTES.LEGAL_NOTICE}
            className={classes.simpleLink}
            onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.LEGAL_NOTICE)}
          >
            <FooterButton title={t('footer.legal_notice')} />
          </Link>
          <div className={classes.divider} />
        </div>
        <div>
          <div className={classes.groupCG}>
            <Link
              to={ROUTES.CGU}
              onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.CGU)}
              className={classes.simpleLink}
            >
              <FooterButton title={t('footer.CGU')} />
            </Link>
            <Link
              to={ROUTES.CGL}
              className={classes.simpleLink}
              onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.CGL)}
            >
              <FooterButton title={t('footer.CGL')} />
            </Link>
          </div>
          <div className={classes.divider} />
          <Link
            to={ROUTES.PERSONAL_DATA}
            onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.PRIVACY)}
            className={classes.simpleLink}
          >
            <FooterButton title={t('footer.privacy')} />
          </Link>
          <div className={classes.divider} />
          <Link
            to={ROUTES.COOKIES}
            onClick={() => trackClickEvent(MATOMO_CATEGORY.FOOTER.COOKIES)}
            className={classes.simpleLink}
          >
            <FooterButton title={t('footer.cookies')} />
          </Link>
          <div className={classes.divider} />
          { isMobile && (
            <div>
              <LanguageSelector />
              <div className={classes.divider} />
            </div>
          )}
          <a
            href={t('footer.dkt_be')}
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
            className={classes.dkt_be}
          >
            <FooterButton title={t('footer.decathlon_be')} isBold={isMobile} />
            <Icon
              size={24}
              value="external-link-line"
              variant="default"
            />
          </a>
          <div className={classes.divider} />
        </div>
      </div>
      <div className={classes.logo}>
        <img src={logo} width={390} height={36} alt="" />
      </div>
    </div>
  );
};

export default memo(Footer);
