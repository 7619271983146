import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import type { FC } from 'react';
import {
  useContext, useEffect, useState,
} from 'react';
import GetUserNameUseCase from '@/usecases/GetUserNameUseCase';
import classes from './styles.module.scss';
import { Spacer } from '@/components';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { AuthState } from '@/context/Auth/AuthState';
import { RightsContext } from '@/context/Rights/RightsProvider';
import Assets from '@/core/api/Assets';
import { RightsState } from '@/context/Rights/RightsState';

const hi = Assets.getAssets('/general/hi.svg');

type Props = {
  titleWelcome: string,
  displayHi?: boolean,
};

const Welcome: FC<Props> = ({ titleWelcome, displayHi = true }) => {
  const { t } = useTranslation();

  const [userName, setUserName] = useState<string>();

  const { isAuth } = useContext(AuthContext);
  const { isActive } = useContext(RightsContext);

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      (async () => {
        if (!userName) {
          const name = await GetUserNameUseCase.execute();
          if (name) setUserName(name);
        }
      })();
    }
  }, [isAuth, userName]);

  return (
    <div className={classnames(classes.root)}>
      { displayHi && (
        <>
          <img src={hi} alt="hi" />
          <Spacer vertical={1} />
        </>
      )}
      {isActive === RightsState.ACTIVE
        && (
          <>
            <div className="vtmn-typo_title-5 vtmn-text-grey">{t('homepage.title_name', { name: userName })}</div>
            <Spacer vertical={1} />
          </>
        )}
      <div className="vtmn-typo_title-2">{titleWelcome}</div>
    </div>
  );
};

export default Welcome;
