import type { AxiosResponse } from 'axios';
import type { BundleFromBack as BundleType } from '@/types/Bundle';
import Api from '../Api';

class Bundle extends Api {
  protected getBaseUrl(): string {
    return 'bundle';
  }

  public get(): Promise<AxiosResponse<BundleType[]>> {
    return this.axiosInstance.get<BundleType[]>('');
  }

  public getByModelCode(modelCode: string): Promise<AxiosResponse<BundleType>> {
    return this.axiosInstance.get(`/${modelCode}`);
  }
}

export default Bundle;
