import type { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  createContext, useCallback, useMemo, useState,
} from 'react';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';

export type SortPricesContextType = {
  sorting: string,
  setSorting: Dispatch<SetStateAction<string>>,
  isSortPriceOpen: boolean,
  setIsSortPriceOpen: Dispatch<SetStateAction<boolean>>,
  handleOpen: () => void,
};

export const SortPriceContext = createContext<SortPricesContextType>({
  sorting: 'default',
  setSorting: () => null,
  isSortPriceOpen: false,
  setIsSortPriceOpen: () => null,
  handleOpen: () => null,
});

export const SortPriceProvider = ({ children }: { children: ReactNode }) => {
  const [sorting, setSorting] = useState<string>('default');
  const [isSortPriceOpen, setIsSortPriceOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!isSortPriceOpen) {
      GoogleAnalytics.hit(GaEvents.CLICK, {
        label: GA_LABELS.CATALOG.USE_FILTER,
        filter_terms: 'SORT',
      });
    }
    setIsSortPriceOpen((isOpen) => !isOpen);
  }, [isSortPriceOpen]);

  const value = useMemo(() => ({
    sorting,
    setSorting,
    isSortPriceOpen,
    setIsSortPriceOpen,
    handleOpen,
  }), [sorting, isSortPriceOpen, handleOpen]);
  return (
    <SortPriceContext.Provider value={value}>
      {children}
    </SortPriceContext.Provider>
  );
};
