import type { FC } from 'react';
import parse from 'html-react-parser';
import classes from './styles.module.scss';
import type { PageContent } from '@/types/Page';
import {
  ContentPageParagraph,
  ContentPageTitle, Page,
} from '@/components';

type ContentPageProps = {
  content: PageContent,
};

const ContentPage: FC<ContentPageProps> = ({ content }) => (
  <Page className={classes.root}>
    <ContentPageTitle>{parse(content.title)}</ContentPageTitle>
    <ContentPageParagraph>
      {parse(content.content!)}
    </ContentPageParagraph>
  </Page>
);

export default ContentPage;
