import UseCaseInterface from './UseCaseInterface';
import { SelectionRepository } from '@/repositories/SelectionRepository';

type DeleteItemUserSelection = {
  uuid: string,
};

class DeleteItemUserSelectionUseCase extends UseCaseInterface<DeleteItemUserSelection, any> {
  async execute(infos: DeleteItemUserSelection) {
    await SelectionRepository.deleteLockerItem(infos.uuid);
  }
}

export default new DeleteItemUserSelectionUseCase();
