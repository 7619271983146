import { useNavigate } from 'react-router-dom';
import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { BackButton } from '@/components';

type ContentPageTitleProps = {
  children: JSX.Element | string | JSX.Element[],
  displayBackButton?: boolean,
};

const ContentPageTitle: FC<ContentPageTitleProps> = ({ children, displayBackButton = true }) => {
  const navigateTo = useNavigate();

  return (
    <div className={classes.root}>
      { displayBackButton && <BackButton onBack={() => { navigateTo(-1); }} /> }
      <span className={classNames('vtmn-typo_title-4 vtmn-font-bold', classes.title, displayBackButton ? classes.margin_left : '')}>
        {children}
      </span>
    </div>
  );
};

export default ContentPageTitle;
