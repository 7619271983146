import type { FC } from 'react';
import { useCallback } from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { Spacer } from '@/components';
import { Rating } from './Rating';
import { useResponsive } from '@/hooks/useResponsive';
import currencyFormatter from '@/utils/currencyFormatter';
import { Informations } from './Informations';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';

type Props = {
  isHighlight?: boolean,
  title: string,
  description: string,
  price: number | null,
  rateCount?: number,
  rateStars?: string,
  packshot: string,
  onClick: () => void;
  modelCode: string;
};

const ProductCell: FC<Props> = ({
  isHighlight,
  packshot,
  price,
  rateStars,
  rateCount,
  description,
  title,
  onClick,
  modelCode,
}) => {
  const { isMobile } = useResponsive();

  const clickOnProductCell = useCallback(() => {
    if (isHighlight) {
      GoogleAnalytics.hit(GaEvents.CLICK, {
        label: GA_LABELS.CATALOG.HIGHLIGHTED_PRODUCT,
        sport_id: modelCode,
      });
    }
    onClick();
  }, [modelCode, isHighlight, onClick]);

  return (
    <button className={classes.main} onClick={clickOnProductCell}>
      <div className={classnames(classes.img, isHighlight ? classes.highlight : classes.normal)}>
        <img src={packshot} alt="img" className={classnames(isHighlight ? classes.highlight : classes.normal)} loading="lazy" />
      </div>
      {!isHighlight && <div className={classes.divider} />}
      <div className={classnames(isHighlight ? classes.detailsHighlight : classes.details)}>
        <Informations title={title} isMobile={isMobile} description={description} isHighlight={isHighlight} />

        {(!isHighlight || isMobile) && (
        <>
          <Spacer vertical={isHighlight ? 2 : 1} />
          <Rating rateCount={rateCount} rateStars={rateStars} />
          <Spacer vertical={1} />
        </>
        )}

        { price
        && <p className={classnames('vtmn-font-bold', classes.price)}>{currencyFormatter(price)}</p>}
      </div>
    </button>
  );
};

export default ProductCell;
