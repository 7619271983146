import type { AxiosResponse } from 'axios';
import Api from '../../Api';
import type { CreateUserSelection, LockerItemFromBack } from '@/types/Locker';

class Selection extends Api {
  protected getBaseUrl(): string {
    return 'selection';
  }

  public get(): Promise<AxiosResponse<LockerItemFromBack[]>> {
    return this.axiosInstance.get<LockerItemFromBack[]>('', {});
  }

  public create(data: CreateUserSelection | CreateUserSelection[]): Promise<AxiosResponse> {
    const infos = Array.isArray(data) ? data.map((d) => ({
      super_model_code: d.superModelCode,
      model_code: d.modelCode,
      item_id: d.itemId,
      quantity: d.quantity,
    })) : {
      super_model_code: data.superModelCode,
      model_code: data.modelCode,
      item_id: data.itemId,
      quantity: data.quantity,
    };
    return this.axiosInstance.post('', infos);
  }

  public validate(): Promise<AxiosResponse> {
    return this.axiosInstance.patch('/validate');
  }

  public async delete(uuid: string) {
    await this.axiosInstance.delete(`/${uuid}`, {});
  }

  public async getNbItems(): Promise<AxiosResponse<number>> {
    return this.axiosInstance.get('/nb_items', {});
  }
}

export default Selection;
