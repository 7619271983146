const BAN_CATEGORIES = 'wpc.ban_categories';
const CATEGORIES = 'wpc.categories';
const CGA = 'wpc.cga';
const CGL = 'wpc.cgl';
const CGU = 'wpc.cgu';
const COOKIES = 'wpc.cookies';
const LANDING = 'wpc.landing';
const LEGAL_NOTICE = 'wpc.legal_notice';
const PERSONAL_DATA = 'wpc.personal_data';
const TESTIMONY = 'wpc.testimony';
const BUNDLE = 'wpc.bundle';
const FAQ = 'wpc.faq';

class SessionStorage {
  static getBanCategories(): string | null {
    return sessionStorage.getItem(BAN_CATEGORIES);
  }

  static setBanCategories(categories : string) {
    return sessionStorage.setItem(BAN_CATEGORIES, categories);
  }

  static getCategories(): string | null {
    return sessionStorage.getItem(CATEGORIES);
  }

  static setCategories(categories : string) {
    return sessionStorage.setItem(CATEGORIES, categories);
  }

  static getCGA():string | null {
    return sessionStorage.getItem(CGA);
  }

  static setCGA(cga : string) {
    sessionStorage.setItem(CGA, cga);
  }

  static getFAQ():string | null {
    return sessionStorage.getItem(FAQ);
  }

  static setFAQ(faq : string) {
    sessionStorage.setItem(FAQ, faq);
  }

  static getCGL():string | null {
    return sessionStorage.getItem(CGL);
  }

  static setCGL(cgl : string) {
    sessionStorage.setItem(CGL, cgl);
  }

  static getCGU():string | null {
    return sessionStorage.getItem(CGU);
  }

  static setCGU(cgu : string) {
    sessionStorage.setItem(CGU, cgu);
  }

  static getCookies():string | null {
    return sessionStorage.getItem(COOKIES);
  }

  static setCookies(cookies : string) {
    sessionStorage.setItem(COOKIES, cookies);
  }

  static getLanding():string | null {
    return sessionStorage.getItem(LANDING);
  }

  static setLanding(landing : string) {
    sessionStorage.setItem(LANDING, landing);
  }

  static getLegalNotice():string | null {
    return sessionStorage.getItem(LEGAL_NOTICE);
  }

  static setLegalNotice(legalNotice : string) {
    sessionStorage.setItem(LEGAL_NOTICE, legalNotice);
  }

  static getPersonalData():string | null {
    return sessionStorage.getItem(PERSONAL_DATA);
  }

  static setPersonalData(personalData : string) {
    sessionStorage.setItem(PERSONAL_DATA, personalData);
  }

  static getTestimonials():string | null {
    return sessionStorage.getItem(TESTIMONY);
  }

  static setTestimonials(testimony : string) {
    sessionStorage.setItem(TESTIMONY, testimony);
  }

  static getBundle(lng: string): string | null {
    return sessionStorage.getItem(`${BUNDLE}-${lng}`);
  }

  static setBundle(bundle: string, lng: string) {
    sessionStorage.setItem(`${BUNDLE}-${lng}`, bundle);
  }
}

export default SessionStorage;
