export const LOCKER_STATE = {
  VALIDATED: 'validated',
  TO_RETURN: 'toReturn',
  LOCKER: 'locker',
  TO_COLLECT: 'toCollect',
  RETURNED: 'returned',
  SELECTION: 'selection',
  SHIPPING: 'shipping',
  IN_PREPARATION: 'inPreparation',
};
