import type { FC } from 'react';
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import classes from './styles.module.scss';
import {
  Accordion, ContentPageSubTitle, ContentPageTitle, Page,
} from '@/components';
import GetStaticFileUseCase from '@/usecases/GetStaticFileUseCase';
import { StaticFiles } from '@/constants/staticFiles';
import type { PersonalDataType } from '@/types/personalData';

const PersonalData: FC = () => {
  const [personalData, setPersonalData] = useState<PersonalDataType | null>(null);

  useEffect(() => {
    (async () => {
      const data = await GetStaticFileUseCase.execute(StaticFiles.PERSONAL_DATA);
      setPersonalData({
        title: data.title,
        content: JSON.parse(data.content),
      });
    })();
  }, []);

  return personalData
    ? (
      <Page className={classes.root}>
        <ContentPageTitle>{ parse(personalData.title) }</ContentPageTitle>
        <ContentPageSubTitle>{ personalData.content.subtitle }</ContentPageSubTitle>
        { personalData.content.sections.map((section, sectionIndex) => (
          <Accordion
            key={sectionIndex}
            isFirst={sectionIndex === 0}
            isLast={sectionIndex === (personalData.content.sections || []).length - 1}
            summary={parse(section.question)}
          >
            { parse(section.answer) }
          </Accordion>
        ))}
      </Page>
    )
    : null;
};

export default PersonalData;
