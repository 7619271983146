import type { FC } from 'react';
import parse from 'html-react-parser';

import classes from './styles.module.scss';
import Page from '../Page';
import ContentPageTitle from '../ContentPageTitle';
import ContentPageSubTitle from '../ContentPageSubTitle';
import type { FAQContent } from '@/types/Page';
import FAQPageSection from './FAQPageSection';

type FAQPageProps = {
  content: FAQContent,
  children?: JSX.Element
};

const FAQPage: FC<FAQPageProps> = ({ content, children }) => (
  <Page className={classes.root}>
    <ContentPageTitle>{parse(content.title)}</ContentPageTitle>
    {content.subtitle ? <ContentPageSubTitle>{parse(content.subtitle)}</ContentPageSubTitle> : null}
    {content.sections.map((section, sectionIndex) => <FAQPageSection section={section} key={sectionIndex} sectionIndex={sectionIndex} />)}
    {children || null}
  </Page>
);

export default FAQPage;
