import type { AxiosResponse } from 'axios';
import Api from '../Api';
import type { CategoryFromBack as CategoryType, ProductByCategoryFromBack } from '@/types/Category';

class Category extends Api {
  protected getBaseUrl(): string {
    return 'categories/';
  }

  public get(): Promise<AxiosResponse<CategoryType[]>> {
    return this.axiosInstance.get<CategoryType[]>('', {});
  }

  public getById(categoryId: string, filters: object, page_nb: number) : Promise<AxiosResponse<ProductByCategoryFromBack>> {
    return this.axiosInstance.get<ProductByCategoryFromBack>(`${categoryId}/products`, {
      params: {
        filters: JSON.stringify(filters),
        page_nb,
      },
    });
  }
}

export default Category;
