import UseCaseInterface from './UseCaseInterface';
import { SelectionRepository } from '@/repositories/SelectionRepository';

class GetUserSelectionNbItemsUseCase extends UseCaseInterface<any, number> {
  async execute(): Promise<number> {
    return SelectionRepository.getUserSelectionNbItems();
  }
}

export default new GetUserSelectionNbItemsUseCase();
