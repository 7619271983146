import type { FC } from 'react';
import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { ContentPage } from '@/components';
import type { StaticFile } from '@/types/StaticFile';
import GetStaticFileUseCase from '@/usecases/GetStaticFileUseCase';
import { StaticFiles } from '@/constants/staticFiles';

const CGU: FC = () => {
  const [cgu, setCgu] = useState<StaticFile>();

  useEffect(() => {
    (async () => {
      const data = await GetStaticFileUseCase.execute(StaticFiles.CGU);
      setCgu(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return cgu ? (
    <ContentPage content={{ content: cgu.content, title: cgu.title }} />
  ) : null;
};

export default CGU;
