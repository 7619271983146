import UseCaseInterface from '@/usecases/UseCaseInterface';
import SearchRepository from '@/repositories/SearchRepository';
import type { SearchResult } from '@/types/Search';

class SearchUseCase extends UseCaseInterface<string, SearchResult> {
  async execute(search: string): Promise<SearchResult> {
    const searchResult = await SearchRepository.search(search);
    return {
      categories: searchResult.categories,
      products: searchResult.models.map((m) => ({
        title: m.brand_label,
        superModelCode: m.super_model_code,
        description: m.label,
        price: m.price,
        rateCount: m.review?.count,
        rateStars: m.review?.average?.toFixed(2)?.toString() ?? '',
        packshot: m.image,
        modelCode: m.model_code,
      })),
    };
  }
}

export default new SearchUseCase();
