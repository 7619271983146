export const MATOMO_CATEGORY: Record<any, any> = {
  HEADER: {
    LOGIN: 'Header_login',
    ACCOUNT: 'header_account',
    LOCKER: 'header_vestiaire',
    TREE: 'header_sportstree',
    HOME: 'header_home',
  },
  FOOTER: {
    LANGUAGE: 'switch_language',
    ENGAGEMENT: 'footer_nosengagements',
    HELP: 'footer_besoindaide',
    FAQ: 'footer_FAQ',
    SERVICES: 'footer_services',
    CGU: 'footer_CGU',
    CGA: 'footer_CGA',
    CGL: 'footer_CGL',
    LEGAL_NOTICE: 'footer_Mentionslégales',
    PRIVACY: 'footer_privacy',
    COOKIES: 'footer_cookies',
  },
  FAQ_PAGE: {
    CONTACT: 'footer_aide_FAQ',
  },
  HELP_PAGE: {
    CONTACT: 'footer_aide_contacterserviceclient',
  },
  HOME_PAGE: {
    CURRENT_SPORT: 'HP_sportdumoment/',
    CURRENT_PRODUCT: 'HP_produitdumoment',
    CITY: 'HP_city/',
  },
  LOCKER: {
    SUBSCRIPTION: 'vestiaire_forfait',
    DELETE_ITEM: 'vestiaire_maselection_trash',
    PRODUCT_UNAVAILABLE: 'vestiaire_maselection_produitpasdispo',
    SINISTER: 'vestiaire_mesproduits_declarerunsinistre',
    OVERLAY: 'vestiaire_mesproduits_overlay',
    SUPPORT_PRODUCT: 'vestiaire_mesproduits_overlay_support',
    OVERLAY_HISTORY: 'vestaire_historique_pageproduit',
  },
  ACCOUNT: {
    FAQ: 'account_FAQ',
    ENGAGEMENT: 'account_nosengagements',
    DECATHLON_ACCOUNT: 'account_comptedecathlon',
    LOGOUT: 'account_deconnexion',
  },
  PRODUCT_PAGE: {
    DISCOVER_SUBSCRIBE: 'discover_subscribe',
  },
  BUNDLE: {
    HOMEPAGE: {
      1: 'HP_bundle/1',
      2: 'HP_bundle/2',
      3: 'HP_bundle/3',
    },
    NAVIGATION: {
      1: 'nav_bundle/1',
      2: 'nav_bundle/2',
      3: 'nav_bundle/3',
    },
    ADD_KIT: 'bundle_kit',
  },
  ERROR_500: 'splashscreen_servor_error',
  LANDING: {
    DISCOVER: 'landing_discover_formules',
    SUBSCRIBE: 'landing_click_subscribe',
    LOGIN: 'landing_click_login',
  },
};

export const MATOMO_ACTION = {
  CLICK: 'ClickEvent',
  BACK_END: 'BackEndEvent',
};
