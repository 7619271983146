import type { FC } from 'react';
import { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTracking } from '@/hooks/useTracking';
import { NavTab } from '@/components';
// import { AuthRepository } from '@/repositories';
import { AuthContext } from '@/context/Auth/AuthProvider';
import classes from './styles.module.scss';
import { ROUTES } from '@/pages/routes';
import { AuthState } from '@/context/Auth/AuthState';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { useResponsive } from '@/hooks/useResponsive';
import { RightsContext } from '@/context/Rights/RightsProvider';
import Assets from '@/core/api/Assets';
import { RightsState } from '@/context/Rights/RightsState';

const DecathlonLogo = Assets.getAssets('/general/decathlonLogo.svg');
const Account = Assets.getAssets('/navigation/account.svg');

const TopBar: FC = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { isAuth } = useContext(AuthContext);
  const { isMobile } = useResponsive();
  const { clickEvent } = useTracking();
  const { isActive, isUnknown } = useContext(RightsContext);

  /* const handleConnect = useCallback(() => {
    clickEvent(MATOMO_CATEGORY.HEADER.LOGIN);
    AuthRepository.signinRedirect();
  }, [clickEvent]); */

  const gotToHome = useCallback(() => {
    clickEvent(MATOMO_CATEGORY.HEADER.HOME);
  }, [clickEvent]);

  const getPathToGo = () => {
    if (isAuth === AuthState.CONNECTED && isActive === RightsState.ACTIVE) {
      return ROUTES.HOME_PAGE;
    }
    return ROUTES.LANDING;
  };
  return (
    <div className={classes.root}>
      <div className={classNames(classes.topBar, { [classes.authTopBar]: isAuth === AuthState.CONNECTED && isMobile })}>
        <Link to={getPathToGo()} onClick={gotToHome}>
          <img
            src={DecathlonLogo}
            alt="logo"
          />
        </Link>
        {/* { (isAuth === undefined || isAuth === AuthState.DISCONNECTED)
          && (
            <div className={classes.button_login}>
              <WPCButton
                onAction={handleConnect}
                size="small"
                variant="tertiary"
              >
                { t('actions.login') }
              </WPCButton>
            </div>
          )} */}
        { (isAuth === AuthState.CONNECTED && isUnknown)
          && (
            <NavTab
              id={1}
              type="account"
              label={t('navigation.account')}
              iconPath={Account}
              isSelected={false}
              onClick={() => navigateTo(ROUTES.ACCOUNT)}
            />
          )}
      </div>
    </div>
  );
};

export default TopBar;
