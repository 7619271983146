import type { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer, WPCButton } from '@/components';

const DataExport: FC = () => {
  const { t } = useTranslation();

  const handleMailTo = (e:MouseEvent) => {
    e.preventDefault();
    window.open(`mailto:team.wpc@decathlon.com?subject=${t('cloture.data.ask_data')}`, '_blank');
  };

  return (
    <div>
      <div className="vtmn-typo_title-4">
        {t('cloture.data.title')}
      </div>
      <Spacer vertical={2} />
      <div className="vtmn-typo_text-1">
        {t('cloture.data.description')}
      </div>
      <Spacer vertical={2} />
      <WPCButton
        variant="primary"
        onAction={handleMailTo}
      >
        {t('cloture.data.action')}
      </WPCButton>
    </div>
  );
};

export default DataExport;
