import type { AxiosResponse, AxiosInstance } from 'axios';
import axios from 'axios';
import { BucketFiles } from '@/constants/bucketFiles';

class Config {
  protected axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${REACT_APP_WPC_RESOURCES}${this.getBaseUrl()}`,
    });

    this.axiosInstance.interceptors.request.use(async (config) => {
      config.headers = config.headers || {};
      config.headers.Accept = 'application/json';
      return config;
    }, (error) => error);

    this.axiosInstance.interceptors.response.use(
      (response) => {
        if ([200, 201, 204].indexOf(response.status) === -1) {
          return Promise.reject(response);
        }
        return response;
      },
      (e) => {
        throw e;
      },
    );
  }

  protected getBaseUrl(): string {
    return '/json';
  }

  public getBanCategories(isProd : Boolean): Promise<AxiosResponse> {
    return this.axiosInstance.get(`${isProd ? BucketFiles.BAN_CATEGORIES_PROD : BucketFiles.BAN_CATEGORIES}?ts=${REACT_APP_TS}`);
  }

  public getCategories(isProd : Boolean): Promise<AxiosResponse> {
    return this.axiosInstance.get(`${isProd ? BucketFiles.CATEGORIES_PROD : BucketFiles.CATEGORIES}?ts=${REACT_APP_TS}`);
  }

  public getCookies(): Promise<AxiosResponse> {
    return this.axiosInstance.get(`${BucketFiles.COOKIES}?ts=${REACT_APP_TS}`);
  }

  public getLanding(): Promise<AxiosResponse> {
    return this.axiosInstance.get(`${BucketFiles.LANDING}?ts=${REACT_APP_TS}`);
  }

  public getTestimonials(): Promise<AxiosResponse> {
    return this.axiosInstance.get(`${BucketFiles.TESTIMONY}?ts=${REACT_APP_TS}`);
  }
}

export default Config;
