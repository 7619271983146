import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import classes from './styles.module.scss';
import NavTab from './NavTab';

import type { Tab } from '@/types/Component';
import Assets from '@/core/api/Assets';

const HomeIcon = Assets.getAssets('/navigation/home.svg');
const HomeSelectedIcon = Assets.getAssets('/navigation/home_selected.svg');
const SearchIcon = Assets.getAssets('/navigation/search.svg');
const SearchSelectedIcon = Assets.getAssets('/navigation/search_selected.svg');
const GarageIcon = Assets.getAssets('/navigation/garage.svg');
const GarageSelectedIcon = Assets.getAssets('/navigation/garage_selected.svg');
const Account = Assets.getAssets('/navigation/account.svg');
const AccountSelectedIcon = Assets.getAssets('/navigation/account_selected.svg');
type NavProps = {
  tabs: Tab[];
  onClick: (index: number) => void;
};

const NavBarMobile: FC<NavProps> = ({ tabs, onClick }) => {
  const location = useLocation();

  const icon = (tab: Tab, isSelected: boolean) => {
    switch (tab.type) {
      case 'home':
        return isSelected ? HomeSelectedIcon : HomeIcon;
      case 'search':
        return isSelected ? SearchSelectedIcon : SearchIcon;
      case 'main':
        return isSelected ? GarageSelectedIcon : GarageIcon;
      case 'account':
        return isSelected ? AccountSelectedIcon : Account;
      default:
        return isSelected ? HomeSelectedIcon : HomeIcon;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {tabs.map((tab) => (
          <NavTab
            key={tab.id}
            id={tab.id}
            type={tab.type}
            label={tab.label}
            iconPath={icon(tab, tab.routePath === location.pathname)}
            isSelected={tab.routePath === location.pathname}
            onClick={() => onClick(tab.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default NavBarMobile;
