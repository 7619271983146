import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Toggle } from '@/components';
import classes from './styles.module.scss';

type Props = {
  traduction: string,
  className: string,
  disabled: boolean,
};

const InfoToggle: FC<Props> = ({
  traduction, className, disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center" className={classes[className]}>
        <Grid item>
          { t(`cookies.what_is.${traduction}.toggle_value`) }
        </Grid>
        <Grid item>
          <Toggle identifier="Toggle" readOnly checked disabled={disabled} />
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoToggle;
