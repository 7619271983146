import { LockerSelection } from '@/core/api';
import type { CreateUserSelection, LockerItemFromBack } from '@/types/Locker';

export class SelectionRepository {
  static async getUserSelection(): Promise<LockerItemFromBack[]> {
    return new LockerSelection().get().then(({ data }) => data);
  }

  static async getUserSelectionNbItems(): Promise<number> {
    // const test = new LockerSelection().getNbItems().then(({ data }) => data);
    return 0;
  }

  static async createUserSelection(data: CreateUserSelection | CreateUserSelection[]) {
    return new LockerSelection().create(data).then(({ data }) => data);
  }

  static async validateSelection() {
    return new LockerSelection().validate();
  }

  static async deleteLockerItem(uuid: string) {
    await new LockerSelection().delete(uuid);
  }
}
