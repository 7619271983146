import { useTranslation, Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { Spacer } from '@/components';
import { useResponsive } from '@/hooks/useResponsive';
import Assets from '@/core/api/Assets';

const InsuranceShield = Assets.getAssets('/landing/insurance_shield.svg');
const InsuranceProduct = Assets.getAssets('/landing/insurance_product.svg');
const InsuranceStore = Assets.getAssets('/landing/insurance_store.svg');
const InsurancePaiement = Assets.getAssets('/landing/insurance_paiement.svg');

const data = [
  {
    title: 'landing.insurances.steps.shield',
    img: InsuranceShield,
  },
  {
    title: 'landing.insurances.steps.product',
    img: InsuranceProduct,
  },
  {
    title: 'landing.insurances.steps.store',
    img: InsuranceStore,
  },
  {
    title: 'landing.insurances.steps.paiement',
    img: InsurancePaiement,
  },
];

const Insurances: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classNames(classes.title, 'vtmn-typo_title-4')}>
          {t('landing.insurances.with_subscription')}
        </div>
        <Spacer vertical={2} />
        <Grid container className={classes.insurance_values} alignItems="stretch">
          {data.map((it, index) => (
            <Grid item md={6} lg={3} key={`insurance_${index}`}>
              <div className={classes.insurance}>
                <img src={it.img} loading="lazy" className={classes.img} alt="insurance icon" />
                <Spacer vertical={1} />
                <Trans className="vtmn-typo_text-3" i18nKey={it.title} />
              </div>
            </Grid>
          ))}
        </Grid>
        {!isMobile && <Spacer vertical={2} />}
      </div>
    </div>
  );
};

export default Insurances;
