import type { AxiosInstance } from 'axios';
import axios from 'axios';
import Language from '@/service/Language/language';
import LocalStorage from '@/utils/LocalStorage';

export default abstract class Api {
  protected axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${REACT_APP_WPC_ENDPOINT}/${this.getBaseUrl()}`,
    });

    this.axiosInstance.interceptors.request.use(async (config) => {
      config.headers = config.headers || {};
      config.headers['x-api-key'] = REACT_APP_WPC_API_KEY;
      config.headers.Authorization = `Bearer ${LocalStorage.getToken()}`;
      config.headers['Accept-Language'] = await Language.get();
      config.headers.Accept = 'application/json';
      return config;
    }, (error) => error);

    this.axiosInstance.interceptors.response.use(
      (response) => {
        if ([200, 201, 204, 206].indexOf(response.status) === -1) {
          return Promise.reject(response);
        }
        return response;
      },
      (e) => {
        throw e;
      },
    );
  }

  protected abstract getBaseUrl(): string;
}
