import type { FC } from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { Rating } from '../Rating';

type Props = {
  title: string,
  isHighlight?: boolean,
  rateCount?: number,
  rateStars?: string,
  isMobile: boolean,
  description: string,
};
export const Informations: FC<Props> = ({
  title,
  isHighlight,
  rateStars,
  rateCount,
  isMobile,
  description,
}) => (
  <div>
    <div className={classnames('vtmn-typo_text-3 vtmn-font-bold', classes.title)}>
      <p>{title}</p>
      {(isHighlight && !isMobile) && <Rating rateCount={rateCount} rateStars={rateStars} />}
    </div>
    <p className={classnames('vtmn-typo_caption-1', classes.description, { [classes.is_highlight]: isHighlight })}>
      {description}
    </p>
  </div>
);
