import type { ReactNode } from 'react';
import {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { AuthRepository } from '@/repositories';
import LocalStorage from '@/utils/LocalStorage';
import { AuthState } from '@/context/Auth/AuthState';
import { useInterval } from '@/hooks/useInterval';
import { useVisibility } from '@/hooks/useVisibility';
import { ROUTES } from '@/pages/routes';
import GetUserRightsUseCase from '@/usecases/GetUserRightsUseCase';
import { SubscriptionStatus } from '@/enum/subscriptionStatus';

export type AuthContextType = {
  isAuth: AuthState | undefined,
  logOut : () => void
  displayInactivePopUp: boolean,
  setDisplayInactivePopUp: (displayInactivePopUp: boolean) => void,
  displayUnknownUserPopUp: boolean,
  setDisplayUnknownUserPopUp: (displayUnknownUserPopUp: boolean) => void,
};

export const AuthContext = createContext<AuthContextType>({
  isAuth: AuthState.LOADING,
  logOut: () => null,
  displayInactivePopUp: false,
  setDisplayInactivePopUp: () => {},
  displayUnknownUserPopUp: false,
  setDisplayUnknownUserPopUp: () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isVisible = useVisibility();

  const [isAuth, setIsAuth] = useState<AuthState>(AuthState.LOADING);
  const [displayInactivePopUp, setDisplayInactivePopUp] = useState<boolean>(false);
  const [displayUnknownUserPopUp, setDisplayUnknownUserPopUp] = useState<boolean>(false);

  useInterval(() => {
    if (isAuth === AuthState.CONNECTED) {
      if (LocalStorage.getToken()) {
        if (!(AuthRepository.isTokenValid() && AuthRepository.isTokenNotExpired())) {
          (async () => {
            await reconnect();
          })();
        }
      }
    }
  }, 60000);

  const updateAuth = useCallback((newAuth : AuthState) => {
    setIsAuth(newAuth);
  }, []);

  const logOut = useCallback(async () => {
    if (isAuth !== AuthState.DISCONNECTED) {
      updateAuth(AuthState.DISCONNECTED);
      return;
    }
    const token = LocalStorage.getToken();
    if (token) {
      await AuthRepository.logOut();
    }
  }, [isAuth, updateAuth]);

  useEffect(() => {
    if (isVisible) {
      if (isAuth === AuthState.CONNECTED) {
        if (LocalStorage.getToken()) {
          if (!(AuthRepository.isTokenValid() && AuthRepository.isTokenNotExpired())) {
            (async () => {
              await reconnect();
            })();
          }
        }
      }
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const reconnect = useCallback(async () => {
    updateAuth(AuthState.LOADING);
    const hasRefresh = await AuthRepository.fetchRefreshToken();
    if (hasRefresh) updateAuth(AuthState.CONNECTED);
    else {
      updateAuth(AuthState.DISCONNECTED);
      AuthRepository.removeStorage();
    }
  }, [updateAuth]);

  const connect = useCallback(async () => {
    if (LocalStorage.getToken()) {
      if ((AuthRepository.isTokenValid() && AuthRepository.isTokenNotExpired())) {
        updateAuth(AuthState.CONNECTED);
      } else {
        await reconnect();
      }
    } else if (searchParams.has('code')) {
      const redirectUri = searchParams.has('target') ? `${window.location.origin}?target=${searchParams.get('target')}` : window.location.origin;
      const hasFetch = await AuthRepository.fetchToken(searchParams.get('code') || '', searchParams.get('state') || '', redirectUri);
      if (hasFetch) {
        updateAuth(AuthState.CONNECTED);
        try {
          const rights = await GetUserRightsUseCase.execute();
          navigateTo(ROUTES.HOME_PAGE);
          if (rights === SubscriptionStatus.INACTIVE) setDisplayInactivePopUp(true);
        } catch (e) {
          // @ts-ignore
          if (e?.response.status === 404) {
            setDisplayUnknownUserPopUp(true);
            navigateTo(ROUTES.LANDING);
          }
        }
      } else updateAuth(AuthState.DISCONNECTED);
    } else {
      updateAuth(AuthState.DISCONNECTED);
    }
  }, [isAuth, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      await connect();
    })();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuth === AuthState.DISCONNECTED) {
      (async () => {
        await logOut();
      })();
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = useMemo(() => ({
    isAuth,
    logOut,
    displayInactivePopUp,
    setDisplayInactivePopUp,
    displayUnknownUserPopUp,
    setDisplayUnknownUserPopUp,
  }), [isAuth, logOut, displayInactivePopUp, displayUnknownUserPopUp]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
