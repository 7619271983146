import UseCaseInterface from './UseCaseInterface';
import { LockerRepository } from '@/repositories';

type Infos = {
  items: string[];
  status: string;
};

class ValidateEquipmentUseCase extends UseCaseInterface<Infos, void> {
  async execute(infos: Infos): Promise<void> {
    return LockerRepository.validateEquipment(infos.items, infos.status);
  }
}

export default new ValidateEquipmentUseCase();
