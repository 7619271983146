import type { TFunction } from 'i18next';
import UseCaseInterface from './UseCaseInterface';
import type { Model as ModelType } from '@/types/Model';
import { ModelRepository } from '@/repositories';
import { ModelBuilder } from '@/builder/ModelBuilder';

export type ModelInfo = {
  model_code: string,
  super_model_code: string,
  t: TFunction<'translation', undefined>,
};

class GetModelUseCase extends UseCaseInterface<ModelInfo, ModelType> {
  async execute(modelInfo: ModelInfo) : Promise<ModelType> {
    const modelRepository = new ModelRepository();
    const modelBuilder = new ModelBuilder();

    const modelfromBack = await modelRepository.GetModelFromBack(modelInfo.model_code, modelInfo.super_model_code);
    const stocks = await modelRepository.GetStockForModel(modelInfo.model_code, modelInfo.super_model_code);

    const modelLight = modelBuilder.GetModelLight(modelfromBack, stocks);
    const packshot = modelBuilder.GetPackshot(modelfromBack.pack_shot);
    const colors = modelBuilder.GetColors(modelfromBack.declinations, modelfromBack.model_code);
    const advantages = modelBuilder.GetAdvantages(modelfromBack.specs.user_benefits);
    const functionalities = modelBuilder.GetFunctionalities(modelfromBack.specs.functionalities);
    const reviews = {
      reviews: {
        globalRating: Number(modelfromBack.reviews?.average_rating?.toFixed(2)) || undefined,
        nbReviews: modelfromBack.reviews?.nb_reviews,
      },
    };
    const composition = modelBuilder.GetComposition(modelfromBack, modelInfo.t);

    return {
      ...modelLight,
      ...packshot,
      ...colors,
      ...advantages,
      ...functionalities,
      ...reviews,
      ...composition,
    };
  }
}

export default new GetModelUseCase();
