import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@/hooks/useResponsive';
import classes from './styles.module.scss';
import Assets from '@/core/api/Assets';
import { Icon } from '@/components';

const CookiesWoman = Assets.getAssets('/cookies/cookiesWoman.svg');
const NotNecessary: FC = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  const contents = ['content1', 'content2', 'content3', 'content4'];

  return (
    <Grid
      container
      direction={isMobile ? 'column-reverse' : 'row'}
      spacing={1}
    >
      <Grid item md={9}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="vtmn-typo_title-4">
              { t('cookies.not_necessary.title') }
            </div>
          </Grid>
          <Grid item xs={12}>
            { t('cookies.not_necessary.description') }
          </Grid>
          { contents.map((content, index) => (
            <Grid item xs={12} container key={index}>
              <Grid item xs={1.5} sm={1} lg={0.5}>
                <Icon value="arrow-right-line" />
              </Grid>
              <Grid item xs={10.5} sm={11} lg={11.5}>
                { t(`cookies.not_necessary.${content}`) }
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item md={3}>
        <img src={CookiesWoman} alt="CookiesWoman" className={classes.img} />
      </Grid>
    </Grid>
  );
};

export default NotNecessary;
