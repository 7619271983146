import type { FC, Ref } from 'react';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import { VtmnTextInput } from '@vtmn/react';

type Props = {
  identifier: string,
  error?: boolean,
  placeholder?: string,
  labelText?: string,
  multiline?: boolean,
  disabled?: boolean,
  valid?: boolean,
  className?: string,
  value?: string | number,
  onChange?: (event: any) => void,
  type?: string,
  refInput?: Ref<HTMLTextAreaElement & HTMLInputElement>,
  onIconClick?: (event: any) => void,
  onFocus?: (event: any) => void,
  onKeyDown?: (event: any) => void,
  icon?: VitamixId,
};

const TextInput: FC<Props> = ({
  refInput,
  type,
  value,
  identifier,
  error,
  placeholder,
  labelText,
  multiline,
  disabled,
  valid,
  onChange,
  className,
  onIconClick,
  onFocus,
  onKeyDown,
  icon,
}) => (
  <VtmnTextInput
    type={type}
    value={value}
    className={className}
    identifier={identifier}
    error={error}
    onIconClick={onIconClick}
    onFocus={onFocus}
    onChange={onChange}
    placeholder={placeholder}
    labelText={labelText}
    onKeyDown={onKeyDown}
    multiline={multiline}
    disabled={disabled}
    valid={valid}
    icon={icon}
    ref={refInput}
  />
);

export default TextInput;
