import UseCaseInterface from './UseCaseInterface';
import { LockerHistoryRepository } from '@/repositories/LockerHistoryRepository';
import type { LockerHistory as LockerHistoryType } from '@/types/Locker';
import { LockerItemBuilder } from '@/builder/LockerItemBuilder';

type InfosFromFront = {
  page_nb: number,
};

class GetUserLockerHistoryUseCase extends UseCaseInterface<InfosFromFront, LockerHistoryType> {
  async execute(infos: InfosFromFront): Promise<LockerHistoryType> {
    const builder = new LockerItemBuilder();
    const lockerHistory = await LockerHistoryRepository.getUserLockerHistory(infos.page_nb);
    return {
      total: lockerHistory.total,
      items: lockerHistory.items.map((i) => builder.GetLockerItem(i)),
    };
  }
}

export default new GetUserLockerHistoryUseCase();
