import type { GaEvents } from '@/enum/GA';

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_path: string }
    ) => void;
  }
}

class GoogleAnalytics {
  static hit(eventType: GaEvents, data: any = {}) {
    if (!(REACT_APP_GA && window.gtag)) {
      // eslint-disable-next-line no-console
      console.error(`Should event "${eventType}", "${JSON.stringify(data)}"`);
      return;
    }

    if (!data.non_interaction) data.non_interaction = true;
    window.gtag('event', eventType, data);
  }
}

export default GoogleAnalytics;
