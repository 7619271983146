import type { FC } from 'react';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import {
  WPCButton, ModalActions, ModalVtmn, ModalDescription,
} from '@/components';
import classes from './styles.module.scss';

type ModalProps = {
  title: string,
  description?: string,
  titleFirstButton?: string,
  titleSecondButton?: string,
  iconFirstButton?: VitamixId,
  iconSecondButton?: VitamixId,
  onClickFirstButton?: () => void;
  onClickSecondButton?: () => void;
  onClose: () => void,
  img?: JSX.Element,
};

const Modal: FC<ModalProps> = ({
  title,
  description,
  titleFirstButton,
  iconFirstButton,
  onClickFirstButton,
  titleSecondButton,
  iconSecondButton,
  onClickSecondButton,
  onClose,
  img,
}) => (
  <ModalVtmn open onClose={onClose} className={classes.root} title={title}>
    <>
      { description
        && (
          <ModalDescription>
            <> { img } </>
            <> { description } </>
          </ModalDescription>
        )}
      { (titleFirstButton || titleSecondButton)
        && (
          <ModalActions className={classes.buttons}>
            <> { titleFirstButton && onClickFirstButton
              && (
                <WPCButton
                  onAction={onClickFirstButton}
                  iconLeft={iconFirstButton}
                  variant="primary-reversed"
                  className={classes.first}
                  size="stretched"
                >
                  {titleFirstButton}
                </WPCButton>
              )}
            </>
            <> { titleSecondButton && onClickSecondButton
              && (
                <WPCButton
                  onAction={onClickSecondButton}
                  iconLeft={iconSecondButton}
                  size="stretched"
                  variant="primary"
                >
                  {titleSecondButton}
                </WPCButton>
              )}
            </>
          </ModalActions>
        )}
    </>
  </ModalVtmn>
);

export default Modal;
