import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccordionVtmn } from '@/components';
import classes from './styles.module.scss';
import type { Cookie } from '@/types/Config';

type Props = {
  cookie: Cookie;
};

const CookieAccordion: FC<Props> = ({ cookie }) => {
  const { t } = useTranslation();

  return (
    <AccordionVtmn
      className={classes.root}
      summary={cookie.tag_solution.join('\n')}
      open={false}
    >
      <div className={classes.details}>
        <div className="vtmn-font-bold">
          { t('cookies.details.tab_titles.objectif') }
        </div>
        <div> { cookie.objectif } </div>
        <Grid container className={classes.infos}>
          <Grid item xs={6} className="vtmn-font-bold">
            { t('cookies.details.tab_titles.name') }
          </Grid>
          <Grid item xs={6} className="vtmn-font-bold">
            { t('cookies.details.tab_titles.lifetime') }
          </Grid>
          <Grid item xs={6}>
            { cookie.names.map((name, index) => (
              <div key={index}> { name } </div>
            ))}
          </Grid>
          <Grid item xs={6}>
            { cookie.lifetime.map((lifetime, index) => (
              <div key={index}> { lifetime } </div>
            ))}
          </Grid>
        </Grid>
      </div>
    </AccordionVtmn>
  );
};

export default CookieAccordion;
