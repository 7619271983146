import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import nl from './nl.json';
import fr from './fr.json';

const resources = {
  nl: { translation: nl },
  fr: { translation: fr },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    backend: {
      requestOptions: {
        cache: 'no-store',
      },
    },
    returnNull: false,
    resources,
    detection: {
      order: ['querystring', 'navigator', 'localStorage'],
    },
    fallbackLng: 'fr',
    supportedLngs: Object.keys(resources),
    keySeparator: '.',
    initImmediate: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
