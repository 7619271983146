import type { ProductFromBack as ProductType } from '@/types/Category';

export class ProductBuilder {
  public TransformProduct(product: ProductType) {
    return {
      modelCode: product.model_code,
      description: product.web_label,
      title: product.brand_label,
      packshotSmall: product.pack_shot && product.pack_shot.length > 0 ? product.pack_shot[0].small_url : '',
      packshotMedium: product.pack_shot && product.pack_shot.length > 0 ? product.pack_shot[0].medium_url : '',
      price: product.price,
      rateStars: product.reviews ? `${product.reviews.average_rating}` : undefined,
      rateCount: product.reviews ? product.reviews.nb_reviews : undefined,
      size: product.size,
      superModelCode: product.super_model_code,
      itemIds: product.article_ids,
    };
  }
}
