import type { FC } from 'react';
import { VtmnAccordion } from '@vtmn/react';

type Props = {
  className: string,
  summary: string,
  open: boolean,
  children: JSX.Element | JSX.Element[],
};

const Accordion: FC<Props> = ({
  className,
  summary,
  open,
  children,
}) => (
  <VtmnAccordion
    className={className}
    summary={summary}
    open={open}
  >
    { children }
  </VtmnAccordion>
);

export default Accordion;
