import UseCaseInterface from './UseCaseInterface';
import type { ProductByCategory } from '@/types/Category';
import { CategoryRepository } from '@/repositories/CategoryRepository';

type InfosFromFront = {
  categoryId: string,
  filter: object,
  pageNb: number,
};

class GetProductsByCategoryIdUseCase extends UseCaseInterface<InfosFromFront, ProductByCategory> {
  async execute(infos: InfosFromFront) : Promise<ProductByCategory> {
    const categoryRepository = new CategoryRepository();
    return categoryRepository.GetProductsByCategoryId(infos.categoryId, infos.filter, infos.pageNb);
  }
}

export default new GetProductsByCategoryIdUseCase();
