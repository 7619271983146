import type { FC } from 'react';
import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { ContentPage } from '@/components';
import type { StaticFile } from '@/types/StaticFile';
import GetStaticFileUseCase from '@/usecases/GetStaticFileUseCase';
import { StaticFiles } from '@/constants/staticFiles';

const LegalNotice: FC = () => {
  const [mentions, setMentions] = useState<StaticFile>();

  useEffect(() => {
    (async () => {
      const data = await GetStaticFileUseCase.execute(StaticFiles.mentions);
      setMentions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return mentions ? <ContentPage content={{ title: mentions.title, content: mentions?.content }} /> : null;
};

export default LegalNotice;
